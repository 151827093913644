import { zodResolver } from '@hookform/resolvers/zod';
import { Icon } from '@iconify/react';
import { forwardRef, useEffect, useImperativeHandle, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

import MarkdownView from '@/Components/MardownView';
import CardInput from '@/Components/Projects/SliderCard/CardInput';
import SelectButton from '@/Components/Projects/SliderCard/SelectButton';
import { useUpdateSelectedIdeaAlignment } from '@/Hooks/react-query/idea-alignment/useUpdateSelected';
import type { User } from '@/Types';
import type {
  IdeaAlignmentContent,
  IdeaAlignmentKeys,
} from '@/Types/creative-idea-alignment/index';
import type { Schema } from '@/Types/creative-idea-alignment/schema';
import { IdeaAlignmentSchema } from '@/Types/creative-idea-alignment/schema';
import type { ProjectProps } from '@/Types/projects';
import { triggerGTMEvent } from '@/Utils/gtm';

export interface CardHandles {
  submitForm: () => void;
}

const Card = forwardRef<
  CardHandles,
  {
    user: User;
    project: ProjectProps;
    id: number;
    totalIdeaAlignment: number;
    ideaAlignment: IdeaAlignmentContent;
    index: number;
    isEditing: boolean;
    isEdited: boolean;
    isEditor: boolean;
    isFetching?: boolean;
    isSelected: boolean;
    onSubmitSuccess: (data: Schema, id: number, index: number) => void;
    onSelectSuccess: () => void;
  }
>(
  (
    {
      user,
      project,
      isFetching = false,
      id,
      totalIdeaAlignment,
      ideaAlignment,
      index,
      isEditing,
      isEdited,
      isSelected,
      isEditor,
      onSelectSuccess,
      onSubmitSuccess,
    },
    ref,
  ) => {
    const { mutate: mutateSelected, isPending: isUpdatingSelected } =
      useUpdateSelectedIdeaAlignment();

    const defaultValues: Schema = useMemo(
      () => ({
        idea_title: ideaAlignment?.idea_title ?? '',
        idea_description: ideaAlignment?.idea_description ?? '',
        reason: ideaAlignment?.reason ?? '',
      }),
      [ideaAlignment],
    );

    const onSubmit = (data: Schema) => {
      onSubmitSuccess(data, id, index);
    };

    const { handleSubmit, reset, control } = useForm<Schema>({
      resolver: zodResolver(IdeaAlignmentSchema),
      defaultValues,
      mode: 'all',
    });

    useImperativeHandle(
      ref,
      () => ({
        submitForm: () => handleSubmit((formData) => onSubmit(formData))(),
      }),
      [handleSubmit, onSubmit],
    );

    useEffect(() => {
      reset(defaultValues);
    }, [isEditing]);

    const handleSelected = (id: number) => {
      const projectSlug = project.slug;

      mutateSelected(
        { id, projectSlug },
        {
          onSuccess: () => {
            triggerGTMEvent({
              event: `Select Idea Alignment`,
              eventCategory: `Button Select Idea Alignment Click`,
              eventAction: 'Click',
              eventLabel: 'Idea Alignment',
              userId: user.email,
              data: {
                type: isSelected ? 'Unselect' : 'Select',
                ...ideaAlignment,
              },
            });

            onSelectSuccess();
          },
        },
      );
    };

    return (
      <>
        <form className="flex w-full flex-col transition duration-500">
          {!isFetching && (
            <div className="inline-flex items-center justify-center gap-8 px-35 py-15 text-center">
              <h1 className="text-1620 font-bold">
                Idea Alignment {index + 1} of {totalIdeaAlignment}
              </h1>
              {(isEdited || id === -1) && (
                <div className="rounded-8 bg-soft-grey-redx px-8 py-4 text-12 leading-14 text-grey-redx">
                  Edited
                </div>
              )}
            </div>
          )}

          {isFetching && (
            <h1 className="mx-auto mb-14 h-20 w-3/12 animate-pulse rounded-full bg-soft-purple-redx" />
          )}
          <div
            className={`grid grid-cols-1 gap-24 p-24 text-black-redx transition duration-500 ${
              isEditing && isSelected ? '!bg-transparent' : ''
            } ${
              isSelected
                ? 'border-t-2 border-blue-redx bg-softer-purple-redx'
                : 'border-t-1 border-stroke-redx'
            }`}
          >
            {Object.keys(IdeaAlignmentSchema.shape).map((field, idx) => (
              <Controller
                key={idx}
                control={control}
                name={field as keyof Schema}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  const title =
                    field.charAt(0).toUpperCase() +
                    field.slice(1).replace(/_/g, ' ');

                  return (
                    <CardInput
                      defaultValue={ideaAlignment?.[field as IdeaAlignmentKeys]}
                      error={error?.message}
                      isEditing={isEditing}
                      isFetching={isFetching}
                      onChange={onChange}
                      placeholder={`Input the ${field.replace(/_/g, ' ')} here`}
                      title={title}
                      value={value}
                    />
                  );
                }}
              />
            ))}
          </div>
          {ideaAlignment?.reason && !isEdited && (
            <div className="mb-20 inline-flex w-full items-center gap-7 border-y-1 border-soft-purple-redx p-10 px-30">
              <div className="mx-20 w-20">
                <Icon
                  className="size-20 text-black-redx"
                  icon="lucide:message-circle-question"
                />
              </div>
              <div className="w-auto">
                <h1 className="text-12 font-semibold leading-24">
                  How Ainstein aligned this Idea?
                </h1>

                <div className="text-12 font-normal leading-18 text-grey-redx">
                  <MarkdownView content={ideaAlignment.reason} />
                </div>
              </div>
            </div>
          )}
        </form>
        <div className="mb-35 mt-15 flex w-full items-center justify-center">
          {id !== -1 && isEditor && (
            <SelectButton
              index={index}
              isEditing={isEditing}
              isLoading={isUpdatingSelected}
              isSelected={isSelected}
              onSelect={() => handleSelected(id)}
              section="idea-alignment"
            />
          )}
        </div>
      </>
    );
  },
);

export default Card;
