import { Icon } from '@iconify/react/dist/iconify.js';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { saveSubInformation } from '@/Context/actions/projectActions';
import { ProjectContext } from '@/Context/ProjectContext';
import { useSidebarContext } from '@/Context/SidebarContext';
import useDifferentArchetypes from '@/Hooks/react-query/audience-archetype/useDifferentArchetypes';
import useHistoryStatus from '@/Hooks/react-query/useHistoryStatus';
import type { ProjectProps } from '@/Types/projects';

import MenuLink from './MenuLink'; // Ensure this import is correct

interface RouteItem {
  href: string;
  label: string;
  active?: boolean;
  disabled?: boolean;
  loading?: boolean;
  icon?: string;
  subMenu?: RouteItem[];
}

const Sidebar: React.FC<{ project: ProjectProps }> = ({ project }) => {
  const { isSidebarOpen } = useSidebarContext();
  const [state, dispatch] = useContext(ProjectContext);
  const { data: differentArchetypes, refetch } =
    useDifferentArchetypes(project);
  const {
    isMarketResearchDemand,
    isMarketResearchTrend,
    isProductResearchProductDescription,
    isProductResearchMainFeature,
    isProductResearchMarcommHighlight,
    isAudienceArchetypeComplete,
    isKeyInsightsComplete,
    isCompetitorAnalysisComplete,
    isOpportunityAmongCompetitionComplete,
    isChallengeAndTaskOfTheBrand,
    isOnePageStrategy,
    isKeyTouchPoint,
    isTargetAudienceAudienceInsight,
    isTargetAudienceBehavioralTrend,
    isTargetAudienceMediaConsumptionPattern,
    isTargetAudiencePerceptionAnalysis,
    isIdeaAlignment,
    isIdeaPersonalization,
    totalCompetitors,
    isIdeaExpansion,
    isIdeaSubmission,
    isPending: isPendingHistory,
    refetch: refetchHistory,
  } = useHistoryStatus(project, state.isEnabledHistoryStatus);
  const location = useLocation();
  const [isStrategyOpen, setIsStrategyOpen] = useState(false);
  const [isCreativeOpen, setIsCreativeOpen] = useState(false);

  const toggleStrategy = () => setIsStrategyOpen(!isStrategyOpen);
  const toggleCreative = () => setIsCreativeOpen(!isCreativeOpen);
  const isActiveRoute = (path: string) => location.pathname.includes(path);

  useEffect(() => dispatch(saveSubInformation(``)), []);

  useEffect(() => {
    refetch();
  }, [
    isMarketResearchDemand,
    isMarketResearchTrend,
    isProductResearchMainFeature,
    isProductResearchMarcommHighlight,
    isProductResearchProductDescription,
    isAudienceArchetypeComplete,
    isKeyInsightsComplete,
    isCompetitorAnalysisComplete,
    isOpportunityAmongCompetitionComplete,
    isChallengeAndTaskOfTheBrand,
    isOnePageStrategy,
    isKeyTouchPoint,
    isTargetAudienceAudienceInsight,
    isTargetAudienceBehavioralTrend,
    isTargetAudienceMediaConsumptionPattern,
    isTargetAudiencePerceptionAnalysis,
    isIdeaAlignment,
    isIdeaPersonalization,
    isIdeaExpansion,
    isIdeaSubmission,
  ]);

  useEffect(() => {
    refetchHistory();
  }, [differentArchetypes?.is_different_archetype]);

  const routeItems: RouteItem[] = [
    {
      href: `/${project.slug}/submission`,
      label: 'Submission',
      disabled: false,
      icon: 'lucide:clipboard-list',
      active: isActiveRoute('/submission'),
    },
    {
      href: `#`,
      label: 'Strategy',
      active:
        isStrategyOpen ||
        isActiveRoute(`/${project.slug}/product-research`) ||
        isActiveRoute(`/${project.slug}/market-research`) ||
        isActiveRoute(`/${project.slug}/target-audience`) ||
        isActiveRoute(`/${project.slug}/audience-archetype`) ||
        isActiveRoute(`/${project.slug}/key-insight`) ||
        isActiveRoute(`/${project.slug}/challenge-and-communication-task`) ||
        isActiveRoute(`/${project.slug}/competitor-analysis`) ||
        isActiveRoute(`/${project.slug}/opportunity-among-competition`) ||
        isActiveRoute(`/${project.slug}/one-page-strategy`) ||
        isActiveRoute(`/${project.slug}/key-touch-point`),
      disabled: false,
      subMenu: [
        {
          href: `/${project.slug}/product-research`,
          icon: 'lucide:package-search',
          label: 'Product Research',
          disabled:
            (!isPendingHistory &&
              !isProductResearchProductDescription.status &&
              !isProductResearchMainFeature.status &&
              !isProductResearchMarcommHighlight.status) ||
            isProductResearchProductDescription.isRegenerated ||
            isProductResearchMainFeature.isRegenerated ||
            isProductResearchMarcommHighlight.isRegenerated,
          loading:
            !isPendingHistory &&
            (isProductResearchProductDescription.isQuerying ||
              isProductResearchMainFeature.isQuerying ||
              isProductResearchMarcommHighlight.isQuerying),
        },
        {
          href: `/${project.slug}/market-research`,
          icon: 'lucide:file-line-chart',
          label: 'Market Research: Industry',
          disabled:
            (!isPendingHistory &&
              !isMarketResearchDemand.status &&
              !isMarketResearchTrend.status) ||
            isMarketResearchDemand.isRegenerated ||
            isMarketResearchTrend.isRegenerated,
          loading:
            !isPendingHistory &&
            (isMarketResearchDemand.isQuerying ||
              isMarketResearchTrend.isQuerying),
        },
        {
          href: `/${project.slug}/target-audience`,
          icon: 'lucide:users-round',
          label: 'Target Audience',
          disabled:
            (!isPendingHistory &&
              !isTargetAudienceAudienceInsight.status &&
              !isTargetAudienceBehavioralTrend.status &&
              !isTargetAudienceMediaConsumptionPattern.status &&
              !isTargetAudiencePerceptionAnalysis.status) ||
            isTargetAudienceAudienceInsight.isRegenerated ||
            isTargetAudienceMediaConsumptionPattern.isRegenerated ||
            isTargetAudiencePerceptionAnalysis.isRegenerated ||
            isTargetAudienceBehavioralTrend.isRegenerated,
          loading:
            !isPendingHistory &&
            (isTargetAudienceAudienceInsight.isQuerying ||
              isTargetAudienceBehavioralTrend.isQuerying ||
              isTargetAudienceMediaConsumptionPattern.isQuerying ||
              isTargetAudiencePerceptionAnalysis.isQuerying),
        },
        {
          href: `/${project.slug}/audience-archetype`,
          icon: 'lucide:book-user',
          label: 'Audience Archetype',
          disabled:
            !isPendingHistory &&
            (!isAudienceArchetypeComplete.status ||
              isAudienceArchetypeComplete.isRegenerated),
          loading: !isPendingHistory && isAudienceArchetypeComplete.isQuerying,
        },
        {
          href: `/${project.slug}/key-insight`,
          icon: 'lucide:lightbulb',
          label: 'Key Insight',
          disabled:
            !isPendingHistory &&
            (!isKeyInsightsComplete.status ||
              isKeyInsightsComplete.isRegenerated ||
              differentArchetypes?.is_different_archetype.key_insight),
          loading: !isPendingHistory && isKeyInsightsComplete.isQuerying,
        },
        {
          href: `/${project.slug}/challenge-and-communication-task`,
          icon: 'lucide:puzzle',
          label: 'Challenge & Communication Task',
          disabled:
            !isPendingHistory &&
            (!isChallengeAndTaskOfTheBrand.status ||
              differentArchetypes?.is_different_archetype.challenges ||
              isChallengeAndTaskOfTheBrand.isRegenerated ||
              isKeyInsightsComplete.isRegenerated ||
              !differentArchetypes?.is_selected_content.key_insight),
          loading: !isPendingHistory && isChallengeAndTaskOfTheBrand.isQuerying,
        },
        {
          href: `/${project.slug}/competitor-analysis`,
          icon: 'lucide:sword',
          label: 'Competitor Analysis',
          disabled:
            (!isPendingHistory && !isCompetitorAnalysisComplete.status) ||
            totalCompetitors === 0 ||
            isCompetitorAnalysisComplete.isRegenerated,
          loading: !isPendingHistory && isCompetitorAnalysisComplete.isQuerying,
        },
        {
          href: `/${project.slug}/opportunity-among-competition`,
          icon: 'lucide:split',
          label: 'Opportunity Among Competition',
          disabled:
            !isPendingHistory &&
            (!isOpportunityAmongCompetitionComplete.status ||
              differentArchetypes?.is_different_archetype.opportunities ||
              isOpportunityAmongCompetitionComplete.isRegenerated),
          loading:
            !isPendingHistory &&
            isOpportunityAmongCompetitionComplete.isQuerying,
        },
        {
          href: `/${project.slug}/one-page-strategy`,
          icon: 'lucide:goal',
          label: 'One-Page Strategy',
          disabled:
            !isPendingHistory &&
            (!isOnePageStrategy.status ||
              differentArchetypes?.is_different_archetype.one_pages ||
              !differentArchetypes?.is_selected_content.key_insight ||
              !differentArchetypes?.is_selected_content.challenges ||
              !differentArchetypes?.is_selected_content.opportunities ||
              isKeyInsightsComplete.isRegenerated ||
              isChallengeAndTaskOfTheBrand.isRegenerated ||
              isOpportunityAmongCompetitionComplete.isRegenerated ||
              isChallengeAndTaskOfTheBrand.isRegenerated ||
              isOnePageStrategy.isRegenerated),
          loading: !isPendingHistory && isOnePageStrategy.isQuerying,
        },
        {
          href: `/${project.slug}/key-touch-point`,
          icon: 'lucide:key-square',
          label: 'Key Touch Point',
          disabled:
            !isPendingHistory &&
            (!isKeyTouchPoint.status ||
              differentArchetypes?.is_different_archetype.key_touch_points ||
              isKeyTouchPoint.isRegenerated ||
              isChallengeAndTaskOfTheBrand.isRegenerated ||
              isOnePageStrategy.isRegenerated ||
              isKeyInsightsComplete.isRegenerated),
          loading: !isPendingHistory && isKeyTouchPoint.isQuerying,
        },
      ],
    },
    {
      href: `#`,
      label: 'Creative',
      active:
        isCreativeOpen ||
        isActiveRoute(`/${project.slug}/creative-idea`) ||
        isActiveRoute(`/${project.slug}/idea-alignment`) ||
        isActiveRoute(`/${project.slug}/idea-personalization`) ||
        isActiveRoute(`/${project.slug}/idea-expansion`),

      disabled: false,
      subMenu: [
        {
          href: `/${project.slug}/creative-idea`,
          icon: 'lucide:sprout',
          label: 'Creative Idea',
          disabled:
            !isPendingHistory &&
            (!isIdeaSubmission.status ||
              isIdeaSubmission.isRegenerated ||
              isKeyInsightsComplete.isRegenerated ||
              isChallengeAndTaskOfTheBrand.isRegenerated),
          loading: false,
        },
        {
          href: `/${project.slug}/idea-alignment`,
          icon: 'lucide:arrows-up-from-line',
          label: 'Idea Alignment',
          disabled:
            !isPendingHistory &&
            (!isIdeaAlignment.status ||
              isIdeaAlignment.isRegenerated ||
              isKeyInsightsComplete.isRegenerated ||
              isChallengeAndTaskOfTheBrand.isRegenerated),
          loading: !isPendingHistory && isIdeaAlignment.isQuerying,
        },
        {
          href: `/${project.slug}/idea-personalization`,
          icon: 'lucide:person-standing',
          label: 'Idea Personalization',
          disabled:
            !isPendingHistory &&
            (!isIdeaPersonalization.status ||
              differentArchetypes?.is_different_archetype
                .idea_personalizations ||
              isIdeaPersonalization.isRegenerated ||
              isKeyInsightsComplete.isRegenerated ||
              isChallengeAndTaskOfTheBrand.isRegenerated),
          loading: !isPendingHistory && isIdeaPersonalization.isQuerying,
        },
        {
          href: `/${project.slug}/idea-expansion`,
          icon: 'lucide:expand',
          label: 'Idea Expansion',
          disabled:
            !isPendingHistory &&
            (!isIdeaExpansion.status ||
              isIdeaExpansion.isRegenerated ||
              isOnePageStrategy.isRegenerated ||
              isKeyTouchPoint.isRegenerated ||
              isKeyInsightsComplete.isRegenerated ||
              differentArchetypes?.is_different_archetype.idea_expansions),
          loading: !isPendingHistory && isIdeaExpansion.isQuerying,
        },
      ],
    },
  ];

  const renderMenu = (items: RouteItem[]) => {
    return items.map((item, index) => (
      <React.Fragment key={index}>
        {item.href !== '#' && (
          <li>
            <MenuLink
              disabled={item.disabled}
              icon={item.icon}
              isLoading={item.loading}
              to={item.href === '#' ? '#' : item.href}
            >
              {item.label}
            </MenuLink>
          </li>
        )}
        {item.href === '#' && (
          <button
            className="inline-flex w-full cursor-pointer justify-between border-b-2 border-stroke-redx py-5 text-14 text-gray-200"
            onClick={() =>
              item.label === 'Strategy' ? toggleStrategy() : toggleCreative()
            }
          >
            {item.label}
            <Icon
              className={`size-24 ${item.active ? 'rotate-180' : 'rotate-0'}`}
              icon="lucide:chevron-down"
            />
          </button>
        )}
        {/* Submenu items */}
        <ul
          className={`flex flex-col gap-8 transition-all duration-300 ease-in-out   ${
            item.active ? 'h-fit opacity-100' : 'hidden max-h-0 opacity-0'
          }`}
        >
          {item.subMenu?.map((subItem, subIndex) => (
            <li key={subIndex}>
              <MenuLink
                disabled={subItem.disabled}
                icon={subItem.icon}
                isLoading={subItem.loading}
                to={subItem.href}
              >
                {subItem.label}
              </MenuLink>
            </li>
          ))}
        </ul>
      </React.Fragment>
    ));
  };

  return (
    <aside
      aria-label="Sidebar"
      className={`fixed inset-y-0 left-0 z-30 h-full min-w-3/12 shadow-xl transition-transform ${
        isSidebarOpen ? 'block' : 'hidden'
      }`}
    >
      <div className="flex size-full max-h-screen flex-col justify-between overflow-y-auto bg-darkblue-redx py-60 scrollbar-hide">
        <ul className="flex h-full flex-col gap-y-8 overflow-y-auto px-30 pt-30 scrollbar-hide">
          {renderMenu(routeItems)}
        </ul>
      </div>
      <div className="relative">
        <div className="flex w-full flex-col justify-between gap-y-10 px-30">
          <div className="overflow-custom flex w-full flex-col items-start gap-y-8 space-y-2 overflow-y-auto border-t-2 border-stroke-redx py-40 font-medium">
            <div className="inline-flex w-full cursor-not-allowed items-center justify-center rounded-8 border-2 border-soft-purple-redx py-8 text-14 text-gray-200">
              Duplicate Project
              <span className="ml-8 inline-flex items-center rounded-4 bg-soft-purple-redx px-4 py-2 text-center text-8 font-bold uppercase text-blue-redx">
                Coming Soon
              </span>
            </div>
            <div className="inline-flex w-full justify-center rounded-8 border-2 border-soft-purple-redx py-8 text-14 text-gray-200">
              View History
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
