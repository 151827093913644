/* eslint-disable no-nested-ternary */
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import { Icon } from '@iconify/react';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import GenerateLoading from '@/Components/GenerateLoading';
import MarkdownEditor from '@/Components/MarkdownEditor';
import MarkdownEditorReadOnly from '@/Components/MarkdownEditorReadOnly';
import ViewInfoModal from '@/Components/Modals/ViewInfoModal';
import EditButton from '@/Components/Projects/EditButton';
import RegenerateButton from '@/Components/Projects/RegenerateButton';
import SourcesList from '@/Components/Projects/SourcesList';
import ErrorLLM from '@/Components/Toast/ErrorLLM';
import {
  saveInformation,
  saveRouteNext,
  saveRoutePrev,
  saveSubInformation,
  setMarketResearchDemand,
  setMarketResearchTrends,
} from '@/Context/actions/projectActions';
import { useGenerate } from '@/Context/hooks/useGenerate';
import { ProjectContext } from '@/Context/ProjectContext';
import type { RouteOption } from '@/Context/reducer/projectReducer';
import { historiesFilter } from '@/Helpers/filter';
import useLatestHistory from '@/Hooks/react-query/useHistoryLatest';
import useHistoryStatus from '@/Hooks/react-query/useHistoryStatus';
import useUser from '@/Hooks/react-query/useUser';
import useRolesBadge from '@/Hooks/useRolesBadge';
import { fetcher } from '@/Services/axios';
import type { ProjectProps } from '@/Types/projects';
import type { SectionList } from '@/Types/tabs';
import { progressPortion } from '@/Utils/dispatcher';
import { triggerGTMEvent } from '@/Utils/gtm';

const MarketResearchIndex: React.FC<{ project: ProjectProps }> = ({
  project,
}) => {
  const [state, dispatch] = useContext(ProjectContext);
  const navigate = useNavigate();
  const maxGenerateLimit = project.max_generated_data;
  const { data: user } = useUser();
  const [isEditing, setIsEditing] = useState(false);
  const [editedData, setEditedData] = useState('');
  const { roles } = useRolesBadge(project, user);
  const {
    isMarketResearchDemand,
    isMarketResearchTrend,
    isTargetAudienceAudienceInsight,
    isTargetAudienceBehavioralTrend,
    isTargetAudienceMediaConsumptionPattern,
    isTargetAudiencePerceptionAnalysis,
    isUpdateState: isPendingMarketResearch,
  } = useHistoryStatus(project);
  const [isGenerateTrends, setIsGenerateTrends] = useState(false);
  const [isGenerateDemand, setIsGenerateDemand] = useState(false);
  const { handleGenerateMarketTrends, handleGenerateMarketDemand } =
    useGenerate(dispatch);

  const isEditor = roles.includes('Owner') || roles.includes('Strategist');
  const sectionList: SectionList[] = [
    {
      title: 'Trends',
      value: 'trends',
      section: 'market_research',
    },
    {
      title: 'Demand',
      value: 'demand',
      section: 'market_research',
    },
  ];
  const totalRegenerate: Record<string, number> = {
    trends: state.marketResearch.trends.data.total_regenerate ?? 0,
    demand: state.marketResearch.demand.data.total_regenerate ?? 0,
  };

  const handleRegenerateButton =
    (section: string) => (data: { prompt: string }) => {
      triggerGTMEvent({
        event: `Regenerate ${section}`,
        eventCategory: `Regenerate Market Research ${section} Click`,
        eventAction: 'Click',
        eventLabel: section,
        userId: user.email,
        data: data.prompt,
      });

      if (section === 'trends') {
        setIsGenerateTrends(true);
        handleGenerateMarketTrends()(project.slug, data.prompt);
      }

      if (section === 'demand') {
        setIsGenerateDemand(true);
        handleGenerateMarketDemand()(project.slug, data.prompt);
      }
    };

  const toggleIsEditing = (content: string) => {
    setEditedData(content);
    setIsEditing(!isEditing);
  };

  const handleCancelEdit = () => {
    setEditedData('');
    setIsEditing(!isEditing);
  };

  const handleSubmitEdit = (subsection: string) => async () => {
    const historyId =
      state.marketResearch[subsection as keyof typeof state.marketResearch].data
        .history_id;

    const { data } = await fetcher.put(
      `/${project.slug}/history/${historyId}`,
      { content: editedData },
    );

    triggerGTMEvent({
      event: `Edit ${subsection}`,
      eventCategory: `Edit Market Research ${subsection} Click`,
      eventAction: 'Click',
      eventLabel: subsection,
      userId: user.email,
      data: editedData,
    });

    if (subsection === 'demand') dispatch(setMarketResearchDemand(data.data));
    if (subsection === 'trends') dispatch(setMarketResearchTrends(data.data));
    setIsEditing(!isEditing);
  };

  const [showViewInfoModal, setShowViewInfoModal] = useState(false);
  const { data, isLoading, refetch } = useLatestHistory(project);

  useEffect(() => {
    const marketResearchTrends = historiesFilter({
      data,
      section: 'market_research',
      subSection: 'trends',
    });
    const marketResearchDemand = historiesFilter({
      data,
      section: 'market_research',
      subSection: 'demand',
    });

    if (marketResearchTrends?.history_id) {
      dispatch(setMarketResearchTrends(marketResearchTrends));
    } else if (
      !isLoading &&
      !isPendingMarketResearch &&
      !isMarketResearchTrend.status
    ) {
      setIsGenerateTrends(true);
      !isMarketResearchTrend.isQuerying &&
        handleGenerateMarketTrends()(project.slug);
    }

    if (marketResearchDemand?.history_id) {
      dispatch(setMarketResearchDemand(marketResearchDemand));
    } else {
      if (
        !isLoading &&
        !isPendingMarketResearch &&
        !isMarketResearchDemand.status &&
        !isMarketResearchDemand.isQuerying
      ) {
        setIsGenerateDemand(true);
        !isMarketResearchDemand.isQuerying &&
          handleGenerateMarketDemand()(project.slug);
      }

      if (!isMarketResearchDemand.isQuerying) {
        setIsGenerateDemand(false);
      }

      if (!isMarketResearchTrend.isQuerying) {
        setIsGenerateTrends(false);
      }
    }
  }, [
    data,
    isMarketResearchDemand,
    isMarketResearchTrend,
    isPendingMarketResearch,
  ]);

  const routeOptionValue: RouteOption = {
    label: 'Next to Target Audience',
    isActive: true,
    isDisabled: !state.submission.is_audience_information_complete,
    onClick: () => navigate(`/${project.slug}/target-audience`),
  };

  useEffect(() => {
    dispatch(saveRouteNext(routeOptionValue));
    dispatch(
      saveRoutePrev({
        label: 'Back',
        isActive: true,
        onClick: () => navigate(`/${project.slug}/product-research`),
      }),
    );
    dispatch(saveInformation(''));
  }, []);

  useEffect(() => {
    let newIsDisabled =
      !isMarketResearchDemand.status && !isMarketResearchTrend.status;
    if (isEditing) {
      newIsDisabled = true;
    } else {
      newIsDisabled = false;
    }

    routeOptionValue.isDisabled = newIsDisabled;

    if (
      !isPendingMarketResearch &&
      (isTargetAudienceAudienceInsight.status ||
        isTargetAudienceMediaConsumptionPattern.status ||
        isTargetAudiencePerceptionAnalysis.status ||
        isTargetAudienceAudienceInsight.isQuerying ||
        isTargetAudienceMediaConsumptionPattern.isQuerying ||
        isTargetAudiencePerceptionAnalysis.isQuerying)
    ) {
      routeOptionValue.isInactive = false;
      routeOptionValue.onClick = () =>
        navigate(`/${project.slug}/target-audience`);
    } else {
      routeOptionValue.isInactive = true;
      routeOptionValue.onClick = () => {
        if (!isEditor) {
          setShowViewInfoModal(true);
          return;
        }

        dispatch(
          saveSubInformation(
            '{text-error-redx} Please input Target Audience in the Submission first.',
          ),
        );
      };
    }

    dispatch(
      saveRouteNext({
        ...routeOptionValue,
      }),
    );
  }, [
    isMarketResearchDemand,
    isMarketResearchTrend,
    isEditing,
    isPendingMarketResearch,
    isTargetAudienceAudienceInsight,
    isTargetAudienceBehavioralTrend,
    isTargetAudienceMediaConsumptionPattern,
    isTargetAudiencePerceptionAnalysis,
    isEditor,
  ]);

  useEffect(() => {
    if (
      isMarketResearchDemand.status &&
      !isMarketResearchDemand.isQuerying &&
      state.marketResearch['demand' as keyof typeof state.marketResearch].data
        .content
    ) {
      setIsGenerateDemand(false);
    }

    if (isMarketResearchDemand.isError) {
      toast.custom((t) => <ErrorLLM t={t} />);
    }

    if (
      isMarketResearchTrend.status &&
      !isMarketResearchTrend.isQuerying &&
      state.marketResearch['trends' as keyof typeof state.marketResearch].data
        .content
    ) {
      setIsGenerateTrends(false);
    }

    if (isMarketResearchTrend.isError) {
      toast.custom((t) => <ErrorLLM t={t} />);
    }

    if (
      (!isMarketResearchTrend.isQuerying && isMarketResearchTrend.status) ||
      (!isMarketResearchDemand.isQuerying && isMarketResearchDemand.status)
    ) {
      refetch();
    }

    if (
      !isPendingMarketResearch &&
      (isMarketResearchDemand.isQuerying || isMarketResearchTrend.isQuerying)
    ) {
      isMarketResearchDemand.isQuerying && setIsGenerateDemand(true);
      isMarketResearchTrend.isQuerying && setIsGenerateTrends(true);
    }
  }, [
    isPendingMarketResearch,
    isGenerateDemand,
    state.marketResearch,
    isMarketResearchDemand,
    isMarketResearchTrend,
  ]);

  const handleTabClick = (name: string, value: string) => {
    triggerGTMEvent({
      event: `Tab Market Research ${name}`,
      eventCategory: `Tab Market Research ${name} Click`,
      eventAction: 'Click',
      eventLabel: name,
      userId: user.email,
      data: value,
    });
  };

  return (
    <>
      <title title="Market Research: Industry" />
      <div className="size-full">
        <div className="sticky top-56 z-20 w-full bg-white py-16">
          <h1 className="mb-8 text-25 font-bold leading-none">
            Market Research: Industry
          </h1>
          <span className="text-15 font-normal text-grey-redx">
            Please review and edit this information, considering that the
            provided reference may not always be accurate.
          </span>
        </div>
        <TabGroup className="pb-80">
          <TabList className="flex w-full">
            {sectionList.map((section, index) => (
              <Tab
                key={index}
                className="w-1/2 border-b-1 px-45 pb-10 text-15 text-black-redx data-[selected]:border-b-3 data-[selected]:border-blue-redx data-[selected]:font-bold data-[selected]:text-blue-redx data-[focus]:outline-0 data-[focus]:outline-transparent [&:has(:focus-visible)]:ring-0"
                disabled={isEditing}
                onClick={() =>
                  handleTabClick(
                    section.title,
                    state.marketResearch[
                      section.value as keyof typeof state.marketResearch
                    ].data.content ?? '',
                  )
                }
              >
                {section.title}
              </Tab>
            ))}
          </TabList>
          <TabPanels className="py-24">
            {sectionList.map((section, index) => (
              <TabPanel key={index}>
                {(section.value === 'demand' && isGenerateDemand) ||
                (section.value === 'trends' && isGenerateTrends) ||
                !state.marketResearch[
                  section.value as keyof typeof state.marketResearch
                ].data.content?.length ? (
                  <GenerateLoading
                    progress={progressPortion({
                      progress: Number(
                        (
                          state.marketResearch[
                            section.value as keyof typeof state.marketResearch
                          ].progress / 2
                        ).toFixed(0),
                      ),
                      isQuerying:
                        section.value === 'demand'
                          ? isMarketResearchDemand.isQuerying
                          : section.value === 'trends'
                            ? isMarketResearchTrend.isQuerying
                            : false,
                      isContentAvailable:
                        state.marketResearch[
                          section.value as keyof typeof state.marketResearch
                        ].data.content !== '',
                    })}
                    project={project}
                    section={section}
                    showEmailNotification={
                      section.value === 'demand'
                        ? isMarketResearchDemand.isSendingEmail
                        : section.value === 'trends'
                          ? isMarketResearchTrend.isSendingEmail
                          : false
                    }
                  />
                ) : (
                  <>
                    <div className="mb-8 flex w-full items-center justify-end">
                      {isEditor && !isEditing && (
                        <RegenerateButton
                          limit={totalRegenerate[section.value]}
                          maxLimit={maxGenerateLimit}
                          onSubmit={handleRegenerateButton(section.value)}
                          section={section.value}
                        />
                      )}
                    </div>
                    {isEditing ? (
                      <div className="mb-12">
                        <MarkdownEditor
                          content={
                            state.marketResearch[
                              section.value as keyof typeof state.marketResearch
                            ].data.content ?? ''
                          }
                          onChange={(html) => {
                            setEditedData(html);
                          }}
                        />
                      </div>
                    ) : (
                      <>
                        {!isLoading &&
                          state.marketResearch[
                            section.value as keyof typeof state.marketResearch
                          ].data !== null && (
                            <MarkdownEditorReadOnly
                              content={
                                state.marketResearch[
                                  section.value as keyof typeof state.marketResearch
                                ].data.content
                              }
                            />
                          )}
                        {isLoading && (
                          <div className="full my-20 h-155 w-full animate-pulse gap-x-12 rounded-10 border-1 border-soft-purple-redx bg-soft-purple-redx px-15 py-12" />
                        )}
                      </>
                    )}
                    <div className="mb-24 flex w-full items-center justify-end gap-x-15">
                      {isEditor &&
                        (isEditing ? (
                          <>
                            <button
                              className="inline-flex cursor-pointer items-center gap-x-4 text-error-redx"
                              onClick={handleCancelEdit}
                            >
                              <span className="text-15 font-semibold">
                                Cancel
                              </span>
                              <Icon className="size-20" icon="lucide:x" />
                            </button>
                            <button
                              className="inline-flex cursor-pointer items-center gap-x-4 text-blue-redx"
                              id="btn-edit-market-research"
                              onClick={handleSubmitEdit(section.value)}
                            >
                              <span className="text-15 font-semibold">
                                Save
                              </span>
                              <Icon className="size-20" icon="lucide:save" />
                            </button>
                          </>
                        ) : (
                          <EditButton
                            toggleEditing={() =>
                              toggleIsEditing(
                                state.marketResearch[
                                  section.value as keyof typeof state.marketResearch
                                ].data.content,
                              )
                            }
                          />
                        ))}
                    </div>
                    <div className="mr-1 flex w-full pb-60">
                      {!isLoading &&
                        state.marketResearch[
                          section.value as keyof typeof state.marketResearch
                        ].data.sources && (
                          <SourcesList
                            sources={
                              state.marketResearch[
                                section.value as keyof typeof state.marketResearch
                              ].data.sources
                            }
                          />
                        )}
                      {isLoading && (
                        <div className="inline-flex gap-10">
                          {Array.from({ length: 3 }).map((_, index) => (
                            <div
                              key={index}
                              className="full flex h-65 min-w-363 animate-pulse items-center gap-x-12 rounded-10 border-1 border-soft-purple-redx bg-soft-purple-redx px-15 py-12"
                            />
                          ))}
                        </div>
                      )}
                    </div>
                  </>
                )}
              </TabPanel>
            ))}
          </TabPanels>
        </TabGroup>
      </div>
      <ViewInfoModal
        isOpen={showViewInfoModal}
        onClose={() => setShowViewInfoModal(false)}
      />
    </>
  );
};

export default MarketResearchIndex;
