/* eslint-disable @typescript-eslint/naming-convention */
import TagManager from 'react-gtm-module';

interface GTMEventProps {
  event: string;
  eventCategory: string;
  eventAction: string;
  userId?: string;
  timestamp?: number;
  eventLabel?: string;
  [key: string]: any;
}

const triggerGTMEvent = ({
  event,
  eventCategory,
  eventAction,
  eventLabel,
  userId,
  timestamp,
  ...additionalData
}: GTMEventProps): void => {
  TagManager.dataLayer({
    dataLayer: {
      event,
      eventCategory,
      eventAction,
      eventLabel,
      userId,
      timestamp,
      ...additionalData,
    },
  });
};

export { triggerGTMEvent };
