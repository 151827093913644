/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-console */
import { zodResolver } from '@hookform/resolvers/zod';
import { Icon } from '@iconify/react/dist/iconify.js';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import GenerateLoading from '@/Components/GenerateLoading';
import MarkdownEditor from '@/Components/MarkdownEditor';
import EditButton from '@/Components/Projects/EditButton';
import RegenerateButton from '@/Components/Projects/RegenerateButton';
import SourcesList from '@/Components/Projects/SourcesList';
import { useGenerate } from '@/Context/hooks/useGenerate';
import { ProjectContext } from '@/Context/ProjectContext';
import useUpdateTargetAudience from '@/Hooks/react-query/target-audience/useUpdateTargetAudience';
import useHistoryStatus from '@/Hooks/react-query/useHistoryStatus';
import useUser from '@/Hooks/react-query/useUser';
import useRolesBadge from '@/Hooks/useRolesBadge';
import type { ProjectProps, SourcesType } from '@/Types/projects';
import type { SectionList } from '@/Types/tabs';
import type {
  PerceptionAnalysisProps,
  TargetAudienceItem,
} from '@/Types/target_audience';
import type { AudiencePerceptionAnalysisSchema } from '@/Types/target_audience/schema-audience-perception-analysis';
import { AudiencePerceptionAnalysis } from '@/Types/target_audience/schema-audience-perception-analysis';
import { progressPortion } from '@/Utils/dispatcher';
import { triggerGTMEvent } from '@/Utils/gtm';

import { jsonToMarkdown, markdownToJson } from '../Utils/utils';
import TargetAudienceList from './TargetAudienceList';

interface Props {
  project: ProjectProps;
  section: SectionList;
  isEditing: boolean;
  isLoading: boolean;
  isGeneratePerceptionAnalysis: boolean;
  toggleIsEditing: () => void;
}

const CardPerceptionAnalysis: React.FC<Props> = ({
  project,
  section,
  isEditing,
  isLoading,
  isGeneratePerceptionAnalysis,
  toggleIsEditing,
}) => {
  const [state, dispatch] = useContext(ProjectContext);
  const [isGenerate, setIsGenerate] = useState(false);
  const { data: user } = useUser();
  const maxGenerateLimit = project.max_generated_data;
  const { mutate: updateTargetAudience } = useUpdateTargetAudience();
  const { roles } = useRolesBadge(project, user);
  const [parsedData, setParsedData] = useState<PerceptionAnalysisProps | null>({
    brand_perception: [],
    sector_perception: [],
    product_category_perception: [],
  });
  const [brandPerception, setBrandPerception] = useState<TargetAudienceItem[]>(
    [],
  );
  const [sectorPerception, setSectorPerception] = useState<
    TargetAudienceItem[]
  >([]);
  const [productCategoryPerception, setProductCategoryPerception] = useState<
    TargetAudienceItem[]
  >([]);

  const {
    handleGenerateTargetAudiencePerceptionAnalysis,
    handleSetTargetAudiencePerceptionAnalysis,
  } = useGenerate(dispatch);

  const stateProps = state.targetAudience.perception_analysis;
  const { progress } = stateProps;
  const { data } = stateProps;
  const { content } = data;
  const { sources } = data;
  const totalRegenerate: number = data.total_regenerate
    ? data.total_regenerate
    : 0;
  const isEditor = roles.includes('Owner') || roles.includes('Strategist');
  const { isTargetAudiencePerceptionAnalysis: isTargetAudience } =
    useHistoryStatus(project);

  const { reset, setValue, getValues } =
    useForm<AudiencePerceptionAnalysisSchema>({
      resolver: zodResolver(AudiencePerceptionAnalysis),
      defaultValues: {
        brand_perception: '',
        sector_perception: '',
        product_category_perception: '',
      },
      mode: 'all',
    });

  const handleSetParsedData = () => {
    const stringContent = content;
    if (stringContent) {
      try {
        const parsedContent = JSON.parse(
          stringContent,
        ) as PerceptionAnalysisProps;
        setParsedData(parsedContent);
        setBrandPerception(parsedContent.brand_perception);
        setSectorPerception(parsedContent.sector_perception);
        setProductCategoryPerception(parsedContent.product_category_perception);

        reset({
          brand_perception: jsonToMarkdown(parsedContent.brand_perception),
          sector_perception: jsonToMarkdown(parsedContent.sector_perception),
          product_category_perception: jsonToMarkdown(
            parsedContent.product_category_perception,
          ),
        });
      } catch (error) {
        console.error(error, 'error perception analysis');
        console.log(
          'error while parsing =>',
          stringContent,
          'perception analysis',
        );
      }
    }
  };

  const handleSaveData = () => {
    toggleIsEditing();

    const formattedContent: PerceptionAnalysisProps = {
      brand_perception: markdownToJson(getValues('brand_perception')),
      sector_perception: markdownToJson(getValues('sector_perception')),
      product_category_perception: markdownToJson(
        getValues('product_category_perception'),
      ),
    };

    triggerGTMEvent({
      event: `Edit Perception Analysis`,
      eventCategory: `Edit Target Audience Perception Analysis Click`,
      eventAction: 'Click',
      eventLabel: 'Perception Analysis',
      userId: user.email,
      data: JSON.stringify(formattedContent),
    });

    updateTargetAudience(
      {
        payload: JSON.stringify(formattedContent),
        historyId: data.history_id,
        projectSlug: project.slug,
      },
      {
        onSuccess: (data) => {
          handleSetTargetAudiencePerceptionAnalysis()(data.data);
        },
      },
    );
  };

  const handleRegenerateButton = (prompt: string) => {
    setIsGenerate(true);
    triggerGTMEvent({
      event: `Regenerate Perception Analysis`,
      eventCategory: `Regenerate Target Audience Perception Analysis Click`,
      eventAction: 'Click',
      eventLabel: 'Perception Analysis',
      userId: user.email,
      data: prompt,
    });
    handleGenerateTargetAudiencePerceptionAnalysis()(project.slug, prompt);
  };

  useEffect(() => {
    if (!isTargetAudience.isQuerying && parsedData?.brand_perception.length) {
      setIsGenerate(false);
    }
  }, [isTargetAudience.isQuerying, parsedData]);

  useEffect(() => {
    if (isGeneratePerceptionAnalysis) {
      setIsGenerate(true);
    }
  }, [isGeneratePerceptionAnalysis]);

  useEffect(() => {
    handleSetParsedData();
  }, [data]);

  if (isGenerate) {
    return (
      <GenerateLoading
        progress={progressPortion({
          progress: Number((progress / 2).toFixed(0)),
          isQuerying: isTargetAudience.isQuerying,
          isContentAvailable: parsedData?.brand_perception.length !== 0,
        })}
        project={project}
        section={section}
        showEmailNotification={isTargetAudience.isSendingEmail}
      />
    );
  }

  return (
    <>
      {/* content */}
      <div className="flex flex-col gap-24 text-black-redx">
        {parsedData && (
          <>
            <div className="flex flex-col gap-5">
              <div className="flex items-center justify-between">
                <h3 className="text-16 font-bold leading-19">
                  Brand Perception
                </h3>
                {isEditor && !isEditing && (
                  <RegenerateButton
                    limit={totalRegenerate}
                    maxLimit={maxGenerateLimit}
                    onSubmit={(formData) =>
                      handleRegenerateButton(formData.prompt)
                    }
                    section="perception-analysis"
                  />
                )}
              </div>
              {isEditing ? (
                <MarkdownEditor
                  content={jsonToMarkdown(parsedData.brand_perception)}
                  onChange={(html) => {
                    const json = markdownToJson(html);

                    setValue('brand_perception', jsonToMarkdown(json));
                    setBrandPerception(json);
                  }}
                />
              ) : (
                <>
                  {isLoading ? (
                    <div className="full mt-5 h-100 w-full animate-pulse gap-x-12 rounded-10 border-1 border-soft-purple-redx bg-soft-purple-redx px-15" />
                  ) : (
                    <TargetAudienceList items={brandPerception} />
                  )}
                </>
              )}
            </div>

            <div className="flex flex-col gap-5">
              <h3 className="text-16 font-bold leading-19">
                Sector Perception
              </h3>
              {isEditing ? (
                <MarkdownEditor
                  content={jsonToMarkdown(parsedData.sector_perception)}
                  onChange={(html) => {
                    const json = markdownToJson(html);
                    setValue('sector_perception', jsonToMarkdown(json));
                    setSectorPerception(json);
                  }}
                />
              ) : (
                <>
                  {isLoading ? (
                    <div className="full mt-5 h-100 w-full animate-pulse gap-x-12 rounded-10 border-1 border-soft-purple-redx bg-soft-purple-redx px-15" />
                  ) : (
                    <TargetAudienceList items={sectorPerception} />
                  )}
                </>
              )}
            </div>

            <div className="flex flex-col gap-5">
              <h3 className="text-16 font-bold leading-19">
                Product Category Perception
              </h3>
              {isEditing ? (
                <MarkdownEditor
                  content={jsonToMarkdown(
                    parsedData.product_category_perception,
                  )}
                  onChange={(html) => {
                    const json = markdownToJson(html);
                    setValue(
                      'product_category_perception',
                      jsonToMarkdown(json),
                    );
                    setProductCategoryPerception(json);
                  }}
                />
              ) : (
                <>
                  {isLoading ? (
                    <div className="full mt-5 h-100 w-full animate-pulse gap-x-12 rounded-10 border-1 border-soft-purple-redx bg-soft-purple-redx px-15" />
                  ) : (
                    <TargetAudienceList items={productCategoryPerception} />
                  )}
                </>
              )}
            </div>
          </>
        )}
      </div>

      <div className="my-24 flex w-full items-center justify-end gap-x-15">
        {isEditor &&
          (isEditing ? (
            <>
              <button
                className="inline-flex cursor-pointer items-center gap-x-4 text-error-redx"
                onClick={() => {
                  handleSetParsedData();
                  toggleIsEditing();
                }}
              >
                <span className="text-15 font-semibold">Cancel</span>
                <Icon className="size-20" icon="lucide:x" />
              </button>
              <button
                className="inline-flex cursor-pointer items-center gap-x-4 text-blue-redx"
                id="btn-edit-target-audience-perception-analysis"
                onClick={handleSaveData}
              >
                <span className="text-15 font-semibold">Save</span>
                <Icon className="size-20" icon="lucide:save" />
              </button>
            </>
          ) : (
            <EditButton toggleEditing={toggleIsEditing} />
          ))}
      </div>
      <div className="mr-1 flex w-full pb-60">
        {!isLoading && sources && (
          <SourcesList sources={sources as SourcesType[]} />
        )}
        {isLoading && (
          <div className="inline-flex gap-10">
            {Array.from({ length: 3 }).map((_, index) => (
              <div
                key={index}
                className="full flex h-65 min-w-363 animate-pulse items-center gap-x-12 rounded-10 border-1 border-soft-purple-redx bg-soft-purple-redx px-15 py-12"
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default CardPerceptionAnalysis;
