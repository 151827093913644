import { zodResolver } from '@hookform/resolvers/zod';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';

import CardInput from '@/Components/Projects/SliderCard/CardInput';
import SelectButton from '@/Components/Projects/SliderCard/SelectButton';
import useDifferentArchetypes from '@/Hooks/react-query/audience-archetype/useDifferentArchetypes';
import { useUpdateSelectedArchetype } from '@/Hooks/react-query/audience-archetype/useUpdateSelectedArchetype';
import type { User } from '@/Types';
import type { Schema } from '@/Types/audience-archetype/schema';
import { ArchetypeSchema } from '@/Types/audience-archetype/schema';
import type { ProjectProps } from '@/Types/projects';
import { triggerGTMEvent } from '@/Utils/gtm';

export interface CardHandles {
  submitForm: () => void;
}

const Card = forwardRef<
  CardHandles,
  {
    user: User;
    project: ProjectProps;
    totalSelected: number;
    id: number;
    totalArchetype: number;
    archetype: any;
    index: number;
    isEditing: boolean;
    isFetching?: boolean;
    isSelected: boolean;
    isEditor: boolean;
    onSubmitSuccess: (data: Schema, id: number, index: number) => void;
    onSelectSuccess: (isShowPopUp: boolean) => void;
  }
>(
  (
    {
      user,
      project,
      totalSelected,
      isFetching = false,
      id,
      totalArchetype,
      archetype,
      index,
      isEditing,
      isSelected,
      isEditor,
      onSelectSuccess,
      onSubmitSuccess,
    },
    ref,
  ) => {
    const [isSelectedButton, setIsSelectedButton] = useState(true);
    const { mutate: mutateSelected, isPending: isUpdatingSelected } =
      useUpdateSelectedArchetype();
    const { refetch: refetchArchetypes } = useDifferentArchetypes(project);
    const defaultValues: Schema = useMemo(
      () => ({
        archetype_name: archetype.archetype_name ?? '',
        demographics: archetype.demographics ?? '',
        occupation: archetype.occupation ?? '',
        lifestyle: archetype.lifestyle ?? '',
        behavior: archetype.behavior ?? '',
        needs_and_challenges: archetype.needs_and_challenges ?? '',
        potential_buying_motives: archetype.potential_buying_motives ?? '',
        current_trends: archetype.current_trends ?? '',
        source_of_information: archetype.source_of_information ?? '',
        online_search_behavior: archetype.online_search_behavior ?? '',
        purchase_frequency: archetype.purchase_frequency ?? '',
        preferred_sales_channels: archetype.preferred_sales_channels ?? '',
      }),
      [archetype],
    );

    const onSubmit = (data: Schema) => {
      onSubmitSuccess(data, id, index);
    };

    const { handleSubmit, reset, control } = useForm<Schema>({
      resolver: zodResolver(ArchetypeSchema),
      defaultValues,
      mode: 'all',
    });

    useImperativeHandle(
      ref,
      () => ({
        submitForm: () => handleSubmit((formData) => onSubmit(formData))(),
      }),
      [handleSubmit, onSubmit],
    );

    useEffect(() => {
      reset(defaultValues);
    }, [isEditing]);

    useEffect(() => {
      setIsSelectedButton(!isSelected && totalSelected >= 3);
    }, [totalSelected, isSelected]);

    const handleSelected = (id: number) => {
      const projectSlug = project.slug;

      triggerGTMEvent({
        event: `Select Audience Archetype`,
        eventCategory: `Button Select Audience Archetype Click`,
        eventAction: 'Click',
        eventLabel: 'Audience Archetype',
        userId: user.email,
        data: { type: isSelected ? 'Unselect' : 'Select', ...archetype },
      });

      mutateSelected(
        { id, projectSlug },
        {
          onSuccess: (data: any) => {
            onSelectSuccess(data.data.is_show_pop_up);
            refetchArchetypes();
          },
        },
      );
    };

    return (
      <form className="mb-45 flex w-full flex-col transition duration-500">
        {!isFetching && (
          <div className="px-35 py-15 text-center">
            <h1 className="text-1620 font-bold">
              Archetype {index + 1} of {totalArchetype}
            </h1>
          </div>
        )}

        {isFetching && (
          <h1 className="mx-auto mb-14 h-20 w-3/12 animate-pulse rounded-full bg-soft-purple-redx" />
        )}
        <div
          className={`mb-16 grid grid-cols-3 gap-24 p-24 text-black-redx transition duration-500 ${
            isEditing && isSelected ? '!bg-transparent' : ''
          } ${
            isSelected
              ? 'border-t-2 border-blue-redx bg-softer-purple-redx'
              : 'border-t-1 border-stroke-redx'
          }`}
        >
          {Object.keys(ArchetypeSchema.shape).map((field, idx) => (
            <Controller
              key={idx}
              control={control}
              name={field as keyof Schema}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                const title =
                  field === 'purchase_frequency'
                    ? 'Purchase Frequency'
                    : field.charAt(0).toUpperCase() +
                      field.slice(1).replace(/_/g, ' ');

                return (
                  <CardInput
                    defaultValue={archetype[field]}
                    error={error?.message}
                    isEditing={isEditing}
                    isFetching={isFetching}
                    onChange={onChange}
                    placeholder={`Input the ${field.replace(/_/g, ' ')} here`}
                    title={title}
                    value={value}
                  />
                );
              }}
            />
          ))}
        </div>
        {id !== -1 && isEditor && (
          <SelectButton
            index={index}
            isDisabled={isSelectedButton}
            isEditing={isEditing}
            isLoading={isUpdatingSelected}
            isSelected={isSelected}
            onSelect={() => handleSelected(id)}
            section="audience-archetype"
          />
        )}
      </form>
    );
  },
);

export default Card;
