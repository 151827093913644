/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable unused-imports/no-unused-vars */
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import { Icon } from '@iconify/react/dist/iconify.js';
import { useQueryClient } from '@tanstack/react-query';
import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import GenerateLoading from '@/Components/GenerateLoading';
import EditButton from '@/Components/Projects/EditButton';
import RegenerateButton from '@/Components/Projects/RegenerateButton';
import SectionInformation from '@/Components/Projects/SectionInformation';
import SliderContainer from '@/Components/Projects/SliderContainer';
import SourcesList from '@/Components/Projects/SourcesList';
import SelectedCard from '@/Components/SelectedCard';
import ErrorLLM from '@/Components/Toast/ErrorLLM';
import {
  saveInformation,
  saveRouteNext,
  saveRoutePrev,
  saveSubInformation,
  setEnableHistoryStatus,
} from '@/Context/actions/projectActions';
import { useGenerate } from '@/Context/hooks/useGenerate';
import { ProjectContext } from '@/Context/ProjectContext';
import type { RouteOption } from '@/Context/reducer/projectReducer';
import useDifferentArchetypes from '@/Hooks/react-query/audience-archetype/useDifferentArchetypes';
import useIdeaExpansions from '@/Hooks/react-query/idea-expansion/useIdeaExpansions';
import { useSubmitIdeaExpansion } from '@/Hooks/react-query/idea-expansion/useSubmit';
import useHistoryStatus from '@/Hooks/react-query/useHistoryStatus';
import useUser from '@/Hooks/react-query/useUser';
import useRolesBadge from '@/Hooks/useRolesBadge';
import type {
  IdeaExpansion,
  IdeaExpansionContent,
  IdeaExpansionList,
  KeyTouchPointSummary,
} from '@/Types/idea-expansion';
import type { Schema } from '@/Types/idea-expansion/schema';
import type { ProjectProps, SourcesType } from '@/Types/projects';
import type { SectionList } from '@/Types/tabs';
import { progressPortion } from '@/Utils/dispatcher';
import { triggerGTMEvent } from '@/Utils/gtm';

import type { CardHandles } from './Partials/Card';
import Card from './Partials/Card';
import { IconCheckMark } from './Partials/IconCheckMark';
import SelectedKeyTouchPointCard from './Partials/SelectedKeyTouchPointCard';

interface IdeaExpansionProps {
  project: ProjectProps;
}
const Index = ({ project }: IdeaExpansionProps) => {
  const queryClient = useQueryClient();
  const [state, dispatch] = useContext(ProjectContext);
  const [errorTabIndex, setErrorTabIndex] = useState<number[]>();
  const [parsedData, setParsedData] = useState<IdeaExpansionList[]>([]);
  const [editedData, setEditedData] = useState<IdeaExpansionList[]>([]);
  const [sources, setSources] = useState<SourcesType[]>([]);
  const [goToIndex, setGoToIndex] = useState<number | undefined>(undefined);
  const maxGenerateLimit = project.max_generated_data;
  const [totalRegenerate, setTotalRegenerate] = useState<number>(0);
  const [selectedArchetypeId, setSelectedArchetypeId] = useState<number>(0);
  const [selectedFunnel, setSelectedFunnel] = useState<string>('');
  const [selectedRecomNumber, setSelectedRecomNumber] = useState<number>(0);
  const [selectedIndex, setSelectedIndex] = useState<number[] | undefined>();
  const [isGenerate, setIsGenerate] = useState(false);
  const cardRefs = useRef<(CardHandles | null)[]>([]);
  const [isChangeFunnel, setIsChangeFunnel] = useState(false);

  const navigate = useNavigate();
  const { data: user } = useUser();
  const [activeTab, setActiveTab] = useState(0);
  const [activeCategoryTab, setActiveCategoryTab] = useState(0);
  const { roles } = useRolesBadge(project, user);
  const isEditor = roles.includes('Owner') || roles.includes('Creative');
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const tabsArchetypeRef = useRef([]);
  const tabsCategoryTypeRef = useRef([]);
  const [selectedFramework, setSelectedFramework] = useState<string>('');
  const [totalSelected, setTotalSelected] = useState<number>(0);
  const [totalArchetype, setTotalArchetype] = useState<number>(0);
  const [selectedIdeaExpansionContent, setSelectedIdeaExpansionContent] =
    useState<IdeaExpansion[]>();

  const { isIdeaExpansion, isUpdateState: isPendingHistory } = useHistoryStatus(
    project,
    state.isEnabledHistoryStatus,
  );

  const {
    isLoading,
    data,
    refetch: refetchTouchPoint,
  } = useIdeaExpansions(project.slug);

  const { data: differentArchetypes, isPending: isPendingDiffArch } =
    useDifferentArchetypes(project);

  const { handleGenerateIdeaExpansion } = useGenerate(dispatch);

  const { mutate, isPending: isSubmitting } = useSubmitIdeaExpansion();

  const resetState = () => {
    setParsedData([]);
    setSources([]);
    setEditedData([]);
    dispatch(saveSubInformation(''));
    setSelectedArchetypeId(0);
    setSelectedFunnel('');
  };

  const handleRegenerateButton = (prompt: string) => {
    triggerGTMEvent({
      event: `Generate More Idea Expansion`,
      eventCategory: `Generate More Idea Expansion Click`,
      eventAction: 'Click',
      eventLabel: 'Idea Expansion',
      userId: user.email,
      data: { slug: project.slug, prompt },
    });

    setIsGenerate(true);
    resetState();
    handleGenerateIdeaExpansion()(project.slug, prompt);
    dispatch(setEnableHistoryStatus(true));
  };

  const routeOptionValue: RouteOption = {
    label: 'Next to Creative Idea',
    isActive: false,
    isGenerate: false,
    isInactive: true,
  };

  useEffect(() => {
    dispatch(saveRouteNext(routeOptionValue));

    dispatch(
      saveRoutePrev({
        label: 'Back',
        isActive: true,
        onClick: () => navigate(`/${project.slug}/idea-personalization`),
      }),
    );
  }, []);

  const toggleIsEditing = () => setIsEditing(!isEditing);
  const toggleIsAdding = () => setIsAdding(!isAdding);

  const handleNext = () => {
    if (activeTab < parsedData!.length - 1) {
      const nextIndex = activeTab + 1;
      setActiveTab(nextIndex);
      setSelectedArchetypeId(parsedData[nextIndex].id ?? 0);
      setSelectedFunnel(parsedData[nextIndex].idea_expansions[0].funnel ?? '');
      setSelectedRecomNumber(
        parsedData[nextIndex].idea_expansions[0].recommendation_number ?? 0,
      );
      if (
        tabsArchetypeRef.current[nextIndex] &&
        'scrollIntoView' in tabsArchetypeRef.current[nextIndex]
      ) {
        (tabsArchetypeRef.current[nextIndex] as Element).scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center',
        });
      }
    }
  };

  const handlePrev = () => {
    if (activeTab > 0) {
      const prevIndex = activeTab - 1;
      setActiveTab(prevIndex);
      setSelectedArchetypeId(parsedData[prevIndex].id ?? 0);
      setSelectedFunnel(parsedData[prevIndex].idea_expansions[0].funnel ?? '');
      setSelectedRecomNumber(
        parsedData[prevIndex].idea_expansions[0].recommendation_number ?? 0,
      );
      if (
        tabsArchetypeRef.current[prevIndex] &&
        'scrollIntoView' in tabsArchetypeRef.current[prevIndex]
      ) {
        (tabsArchetypeRef.current[prevIndex] as Element).scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center',
        });
      }
    }
  };

  const sectionList: SectionList = {
    title: 'Idea Expansion',
    value: 'expansion',
    section: 'idea',
  };

  const handleCancelEdit = () => {
    setParsedData(editedData);
    toggleIsEditing();

    setTimeout(() => {
      setGoToIndex(-1);
    }, 1000);
  };

  useEffect(() => {
    if (data) {
      const { data: ideaExpansions, is_querying: isQuerying } = data;

      if (isQuerying) {
        setIsGenerate(true);
        resetState();
        return;
      }

      if (ideaExpansions) {
        const {
          lists,
          selected_framework: selectedFrameworkResponse,
          total_regenerate: totalRegenerate,
          sources,
        } = ideaExpansions;

        lists &&
          selectedArchetypeId === 0 &&
          setSelectedArchetypeId(lists[0].id ?? 0);

        if (
          lists &&
          (selectedFunnel === '' ||
            selectedFramework !== selectedFrameworkResponse)
        ) {
          setSelectedFunnel(lists[0].idea_expansions[0]?.funnel ?? '');
          setSelectedRecomNumber(
            lists[0].idea_expansions[0]?.recommendation_number ?? 0,
          );
        }

        setParsedData(lists ?? []);
        setEditedData(lists ?? []);

        setSelectedFramework(selectedFrameworkResponse!);
        setTotalRegenerate(totalRegenerate ?? 0);
        setSources(sources ?? []);

        setIsGenerate(false);
      }
    }

    const totalArchetype = data?.data?.total_archetype || 0;
    const totalSelectedPerArchetype =
      data?.data?.total_selected_per_archetype || 0;
    setTotalSelected(totalSelectedPerArchetype);
    setTotalArchetype(totalArchetype);
  }, [
    data,
    differentArchetypes?.is_different_archetype.idea_expansions,
    isIdeaExpansion,
  ]);

  useEffect(() => {
    if (!isIdeaExpansion.status) {
      dispatch(saveInformation(`0 Idea Expansion in 0 Archetype are selected`));
    }

    if (
      !isPendingDiffArch &&
      differentArchetypes?.is_different_archetype.idea_expansions
    ) {
      if (!isGenerate && !isPendingHistory && !isIdeaExpansion.isQuerying) {
        setIsGenerate(true);
        resetState();
        triggerGTMEvent({
          event: `Generate Idea Expansion`,
          eventCategory: `Generate Idea Expansion Click`,
          eventAction: 'Click',
          eventLabel: 'Idea Expansion',
          userId: user.email,
          data: { slug: project.slug },
        });

        handleGenerateIdeaExpansion()(project.slug);
      }
    }

    if (isIdeaExpansion.isError) {
      toast.custom((t) => <ErrorLLM t={t} />);
    }
  }, [
    parsedData,
    isPendingHistory,
    isPendingDiffArch,
    isGenerate,
    differentArchetypes,
    isIdeaExpansion,
    selectedArchetypeId,
    selectedFunnel,
  ]);

  useEffect(() => {
    !isIdeaExpansion.isQuerying && !isPendingHistory && refetchTouchPoint();
    isIdeaExpansion.isQuerying && !isPendingHistory && setIsGenerate(true);
  }, [
    isIdeaExpansion,
    isGenerate,
    isPendingHistory,
    differentArchetypes?.is_different_archetype.idea_expansions,
  ]);

  const handleTitleFunnel = (value: string) => {
    return value
      .replace(/\bcredible\b/g, 'credibility')
      .replace(/\bpurchase\b/g, 'purchasability')
      .replace(/able\b/g, 'ability')
      .replace('_funnel', '')
      .replace(/_/g, ' ')
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const handleSave = () => {
    cardRefs.current.forEach((ref, index) => {
      if (ref && index === activeIndex) {
        ref.submitForm();
      }
    });
  };

  useEffect(() => {
    if (
      differentArchetypes?.is_different_archetype.idea_expansions ||
      parsedData.length === 0 ||
      isIdeaExpansion.isQuerying
    ) {
      dispatch(setEnableHistoryStatus(true));
      return;
    }
    dispatch(setEnableHistoryStatus(false));
  }, [
    isAdding,
    isEditing,
    differentArchetypes?.is_different_archetype.idea_expansions,
    parsedData,
    isIdeaExpansion.isQuerying,
  ]);

  useEffect(() => {
    const hasMany = totalSelected > 1;
    dispatch(
      saveInformation(
        `${totalSelected} Idea Expansion in ${totalArchetype} Archetype is selected`,
      ),
    );
  }, [totalSelected, totalArchetype]);

  const checkSelectedArchetype = (parsedData: IdeaExpansionList[]) => {
    const totalSelectedPerArchetype = parsedData.map((archetype) => {
      const totalSelected = archetype.idea_expansions.reduce(
        (total, touchPoint) => {
          const selectedInTouchPoint = touchPoint.content!.filter(
            (content) => content.is_selected,
          ).length;
          return total + selectedInTouchPoint;
        },
        0,
      );

      return {
        archetype_id: archetype.id,
        total_selected_touchpoints: totalSelected,
      };
    });

    return totalSelectedPerArchetype
      .map((_, index) =>
        totalSelectedPerArchetype[index].total_selected_touchpoints === 0
          ? index
          : -1,
      )
      .filter((index) => index !== -1);
  };

  useEffect(() => {
    if (parsedData.length) {
      const keyTouchPointContent = (parsedData
        .find((item) => item.id === selectedArchetypeId)
        ?.idea_expansions?.find(
          (item) =>
            item.funnel === selectedFunnel &&
            item.recommendation_number === selectedRecomNumber,
        )?.content ?? []) as IdeaExpansion[];

      const selectedDataWithIndex = keyTouchPointContent
        .map((item, index) => ({ item, index }))
        .filter(({ item }) => item.is_selected);

      if (selectedDataWithIndex) {
        const indices = selectedDataWithIndex.map(({ index }) => index);
        setSelectedIndex(indices);
      }

      setSelectedIdeaExpansionContent(keyTouchPointContent);

      const indexesWithZeroSelected = checkSelectedArchetype(parsedData);

      routeOptionValue.isInactive = !!indexesWithZeroSelected.length;

      if (isAdding || isEditing) {
        routeOptionValue.isInactive = true;
      }

      dispatch(saveRouteNext(routeOptionValue));
    }
  }, [
    parsedData,
    selectedArchetypeId,
    selectedFunnel,
    selectedRecomNumber,
    isAdding,
    isEditing,
  ]);

  const handleSelectTab = (archetypeId: number) => {
    const selectedList = parsedData.find((item) => item.id === archetypeId);
    setSelectedFunnel(selectedList?.idea_expansions[0]?.funnel ?? '');
    setSelectedRecomNumber(
      selectedList?.idea_expansions[0]?.recommendation_number ?? 0,
    );

    setActiveCategoryTab(0);
    setSelectedArchetypeId(archetypeId);

    triggerGTMEvent({
      event: `Tab Idea Expansion`,
      eventCategory: `Tab Idea Expansion Click`,
      eventAction: 'Click',
      eventLabel: 'Idea Expansion',
      userId: user.email,
      data: selectedList,
    });
  };

  const handleSubmit = (
    data: IdeaExpansionContent,
    id: number,
    index: number,
  ) => {
    const payload: Schema = {
      idea_title: data.idea_title!,
      campaign_example: data.campaign_example!,
      campaign_metrics: data.campaign_metrics!,
      idea_description: data.idea_description!,
      preferred_platform: data.preferred_platform!,
      preferred_influencer: data.preferred_influencer!,
      reason_product_brand: data.reason_product_brand!,
      reason_audience_archetype: data.reason_audience_archetype!,
    };

    mutate(
      {
        payload,
        id,
        recomNumber: selectedRecomNumber,
        archetypeId: selectedArchetypeId,
        funnel: selectedFunnel,
        projectSlug: project.slug,
      },
      {
        onSuccess: () => {
          triggerGTMEvent({
            event: `${id === -1 ? 'Add' : 'Update'} Idea Expansion`,
            eventCategory: `Button ${id === -1 ? 'Add' : 'Update'} Idea Expansion  Click`,
            eventAction: 'Click',
            eventLabel: 'Idea Expansion',
            userId: user.email,
            data: payload,
          });

          isAdding && toggleIsAdding();
          isEditing && toggleIsEditing();

          setTimeout(() => {
            refetchTouchPoint();
          }, 700);

          if (isAdding) {
            setTimeout(() => {
              setGoToIndex(index);
            }, 1000);
          }
        },
      },
    );
  };

  const formatSelectedSummaryKeyTouchPoint = (
    keyTouchPoints: KeyTouchPointSummary[],
  ): string => {
    const formattedSummary = keyTouchPoints.map((point) => {
      const label = handleTitleFunnel(point.funnel_name ?? ''); // Default to funnel_name if not mapped
      return `${label} (${point.total})`;
    });

    return formattedSummary.join(', ');
  };

  const reformatKeyTouchPoints = (data: KeyTouchPointSummary[]) => {
    return data.flatMap((point) => {
      return (
        point.details?.map((detail, index) => {
          return {
            title: `${handleTitleFunnel(point.funnel_name ?? '')} Touch Point ${
              detail.number ?? index
            }`,
            content: detail.value,
          };
        }) ?? []
      );
    });
  };

  return (
    <>
      {isGenerate ? (
        <GenerateLoading
          estimateTimeFrom={4}
          estimateTimeTo={9}
          progress={progressPortion({
            progress: Number((state.ideaExpansion.progress / 2).toFixed(0)),
            isQuerying: isIdeaExpansion.isQuerying,
            isContentAvailable: parsedData!.length > 0,
          })}
          project={project}
          section={sectionList}
          showEmailNotification={isIdeaExpansion.isSendingEmail}
        />
      ) : (
        <div className="size-full">
          <div className="sticky top-56 z-20 flex items-center justify-between bg-white py-16">
            <div className="w-fit flex flex-col">
              <div className="mb-8 inline-flex items-center gap-12 text-25 font-bold leading-none">
                Idea Expansion
                <SectionInformation
                  description="Ainstein expand your initial idea in the different touch points based on the selected framework"
                  title="What did Ainstein do with Idea Expansion?"
                />
              </div>
              <span className="text-15 font-normal text-grey-redx">
                Please{' '}
                <span className="font-bold text-black-redx">
                  select one idea for each touch point and archetype
                </span>
                , edit, or add by clicking &apos;Add Idea Expansion
                Manually&apos;.
              </span>
            </div>
            {isEditor && (
              <RegenerateButton
                isGenerateMore
                limit={totalRegenerate}
                maxLimit={maxGenerateLimit}
                onSubmit={(form) => handleRegenerateButton(form.prompt)}
                section="idea-expansion"
              />
            )}
          </div>

          <TabGroup
            className="mt-35 pb-80"
            onChange={setActiveTab}
            selectedIndex={activeTab}
          >
            <div className="relative flex items-center">
              {!isLoading && parsedData!.length > 3 && (
                <button
                  className="p-2 text-gray-500 hover:text-black"
                  disabled={activeTab === 0}
                  onClick={handlePrev}
                >
                  <Icon className="text-25" icon="mingcute:left-line" />
                </button>
              )}
              <TabList className="border-grey-200 flex w-full overflow-x-auto border-b-1 scrollbar-hide">
                {!isLoading &&
                  parsedData.map((archetype, index) => (
                    <Tab
                      key={index}
                      ref={(el: HTMLElement | null) => {
                        if (el) {
                          (tabsArchetypeRef.current as HTMLElement[])[index] =
                            el;
                        }
                      }}
                      className="w-full min-w-5/24 px-25 pb-10 text-15 text-black-redx data-[selected]:border-b-3 data-[selected]:border-blue-redx data-[selected]:font-bold data-[selected]:text-blue-redx data-[focus]:outline-0 data-[focus]:outline-transparent md:min-w-7/12 [&:has(:focus-visible)]:ring-0"
                      disabled={isEditing || isAdding}
                      onClick={() => handleSelectTab(archetype.id ?? 0)}
                    >
                      <div className="-mb-px text-sm inline-flex h-78 w-full items-center justify-center gap-8 py-2 font-medium focus:outline-none">
                        <div className="flex flex-col gap-8">
                          {`Archetype ${index + 1}`}
                          <div className="rounded-8 bg-soft-grey-redx px-8 py-4 font-semibold text-black-redx">
                            {archetype.archetype_content?.archetype_name ?? ''}
                          </div>
                        </div>
                        {errorTabIndex && errorTabIndex.includes(index) && (
                          <Icon
                            className="text-25 text-error-redx"
                            icon="mingcute:information-line"
                          />
                        )}
                      </div>
                    </Tab>
                  ))}

                {isLoading &&
                  Array.from({ length: 3 }).map((_, index) => (
                    <button
                      key={`dummy-${index}`}
                      className="-mb-px text-sm flex h-78 w-full flex-col items-center justify-center gap-8 py-2 font-medium text-gray-600 hover:text-indigo-600 focus:outline-none"
                    >
                      <div className="h-20 w-155 animate-pulse rounded-full bg-soft-purple-redx" />
                      <div className="h-20 w-155 animate-pulse rounded-full bg-soft-purple-redx" />
                    </button>
                  ))}
              </TabList>
              {!isLoading && parsedData!.length > 3 && (
                <button
                  className="p-2 text-gray-500 hover:text-black"
                  disabled={activeTab === parsedData!.length - 1}
                  onClick={handleNext}
                >
                  <Icon className="text-25" icon="mingcute:right-line" />
                </button>
              )}
            </div>
            <TabPanels className="py-24">
              {!isLoading ? (
                parsedData.map((archetype, index) => (
                  <TabPanel key={index}>
                    <div className="pt-5">
                      <SelectedCard
                        data={[
                          {
                            title: 'Archetype Name',
                            content:
                              archetype.archetype_content?.archetype_name ?? '',
                          },
                          {
                            title: 'Demographic',
                            content:
                              archetype.archetype_content?.demographics ?? '',
                          },
                          {
                            title: 'Occupation',
                            content:
                              archetype.archetype_content?.occupation ?? '',
                          },
                          {
                            title: 'Lifestyle',
                            content:
                              archetype.archetype_content?.lifestyle ?? '',
                          },
                          {
                            title: 'Behavior',
                            content:
                              archetype.archetype_content?.behavior ?? '',
                          },
                          {
                            title: 'Need & Challenges',
                            content:
                              archetype.archetype_content
                                ?.needs_and_challenges ?? '',
                          },
                          {
                            title: 'Potential Buying Motives',
                            content:
                              archetype.archetype_content
                                ?.potential_buying_motives ?? '',
                          },
                          {
                            title: 'Current Trends',
                            content:
                              archetype.archetype_content?.current_trends ?? '',
                          },
                          {
                            title: 'Source of Information',
                            content:
                              archetype.archetype_content
                                ?.source_of_information ?? '',
                          },
                          {
                            title: 'Online Search Behavior',
                            content:
                              archetype.archetype_content
                                ?.online_search_behavior ?? '',
                          },
                          {
                            title: 'Purchase Frequency',
                            content:
                              archetype.archetype_content?.purchase_frequency ??
                              '',
                          },
                          {
                            title: 'Preferred Sales Channels',
                            content:
                              archetype.archetype_content
                                ?.preferred_sales_channels ?? '',
                          },
                        ]}
                        title={`Archetype Detail - ${
                          archetype.archetype_content?.archetype_name ?? ''
                        }`}
                      />
                      <div className="mt-24" />
                      <SelectedKeyTouchPointCard
                        // archetypeId={archetype.id!}
                        data={reformatKeyTouchPoints(
                          archetype?.key_touch_point_summary ?? [],
                        ).map((item) => ({
                          ...item,
                          content: item.content ?? '',
                        }))}
                        // projectSlug={project.slug}
                        subtitle={formatSelectedSummaryKeyTouchPoint(
                          archetype?.key_touch_point_summary ?? [],
                        )}
                        title={`Selected Touch Point - ${
                          archetype.archetype_content?.archetype_name ?? ''
                        }`}
                      />
                      <div className="mt-20" />

                      <TabGroup
                        className="flex w-full flex-row gap-24"
                        defaultIndex={activeCategoryTab}
                        onChange={setActiveCategoryTab}
                        selectedIndex={activeCategoryTab}
                      >
                        <TabList className="flex w-2/3 flex-col items-start justify-start border-r-1 border-r-stroke-redx">
                          {parsedData
                            .filter((item) => item.id === selectedArchetypeId)
                            .map((archetype, indexArchetype) => {
                              return archetype.idea_expansions.map(
                                (funnel, indexFunnel) => {
                                  const value = funnel?.total_selected ?? 0;

                                  return (
                                    <Tab
                                      key={`${indexArchetype}-${indexFunnel}`}
                                      ref={(el: HTMLElement | null) => {
                                        if (el) {
                                          (
                                            tabsCategoryTypeRef.current as HTMLElement[]
                                          )[indexArchetype] = el;
                                        }
                                      }}
                                      className="w-full py-10 text-start text-16 font-normal leading-20 text-black-redx data-[selected]:border-blue-redx data-[selected]:font-bold data-[selected]:text-blue-redx"
                                      disabled={isEditing || isAdding}
                                      onClick={() => {
                                        triggerGTMEvent({
                                          event: `Tab Funnel Idea Expansion`,
                                          eventCategory: `Tab Funnel Idea Expansion Click`,
                                          eventAction: 'Click',
                                          eventLabel: 'Idea Expansion',
                                          userId: user.email,
                                          data: funnel!.funnel,
                                        });

                                        setSelectedFunnel(funnel!.funnel ?? '');
                                        setSelectedRecomNumber(
                                          funnel!.recommendation_number ?? 0,
                                        );
                                      }}
                                    >
                                      <p className="font-normal">
                                        {`${handleTitleFunnel(
                                          funnel?.funnel ?? '',
                                        )} ${funnel?.recommendation_number}`}
                                      </p>
                                      {value > 0 ? (
                                        <p className="inline-flex gap-4 text-12 font-semibold leading-14 text-light-success-redx">
                                          Selected <IconCheckMark />
                                        </p>
                                      ) : (
                                        <p className="text-12 font-semibold leading-14 text-grey-redx">
                                          unselected
                                        </p>
                                      )}
                                    </Tab>
                                  );
                                },
                              );
                            })}
                        </TabList>
                        <TabPanels className="flex w-4/5 grow flex-col">
                          <div
                            key={`id_${selectedFunnel}_${selectedRecomNumber}`}
                          >
                            {selectedIdeaExpansionContent?.length && (
                              <SliderContainer
                                goToIndex={goToIndex}
                                isAdding={isAdding}
                                isEditing={isEditing}
                                length={selectedIdeaExpansionContent.length}
                                onActiveIndex={(index) => setActiveIndex(index)}
                                selectedIndexes={
                                  isChangeFunnel ? [] : selectedIndex
                                }
                              >
                                {!isAdding &&
                                  selectedIdeaExpansionContent.map(
                                    (content, index) => (
                                      <div className="mb-24 mt-10">
                                        <Card
                                          key={index}
                                          ref={(el) =>
                                            (cardRefs.current[index] = el)
                                          }
                                          id={content.id ?? -1}
                                          index={index}
                                          isEdited={content.is_edit ?? false}
                                          isEditing={isEditing}
                                          isEditor={isEditor}
                                          isFetching={isChangeFunnel}
                                          isSelected={content.is_selected}
                                          item={content.idea_expansion_content}
                                          onHandleSubmit={handleSubmit}
                                          onSuccessSelected={() => {
                                            refetchTouchPoint();
                                          }}
                                          project={project}
                                          selectedFramework={`${handleTitleFunnel(
                                            selectedFunnel,
                                          )} Touchpoint ${selectedRecomNumber}`}
                                          totalData={
                                            selectedIdeaExpansionContent.length
                                          }
                                          user={user}
                                        />
                                      </div>
                                    ),
                                  )}
                                {isAdding && (
                                  <div className="my-24">
                                    <Card
                                      key={0}
                                      ref={(el) => (cardRefs.current[0] = el)}
                                      id={-1}
                                      index={
                                        selectedIdeaExpansionContent.length
                                      }
                                      isEditing={isEditing || isAdding}
                                      isEditor={isEditor}
                                      isFetching={isChangeFunnel}
                                      isSelected={false}
                                      item={{
                                        idea_title: '',
                                        campaign_example: '',
                                        campaign_metrics: '',
                                        idea_description: '',
                                        preferred_platform: '',
                                        preferred_influencer: '',
                                        reason_product_brand: '',
                                        reason_audience_archetype: '',
                                        idea_expansion_number: 0,
                                      }}
                                      onHandleSubmit={handleSubmit}
                                      project={project}
                                      selectedFramework={`${handleTitleFunnel(
                                        selectedFunnel,
                                      )} Touchpoint ${selectedRecomNumber}`}
                                      totalData={
                                        selectedIdeaExpansionContent.length
                                      }
                                      user={user}
                                    />
                                  </div>
                                )}
                              </SliderContainer>
                            )}
                          </div>

                          <div className="mb-35 flex w-full items-center justify-between border-t-1 border-stroke-redx pt-12">
                            {isEditor &&
                              (!isAdding && !isEditing ? (
                                <button
                                  className="inline-flex gap-x-4 text-15 font-semibold text-blue-redx"
                                  onClick={toggleIsAdding}
                                >
                                  Add Idea Expansion Manually
                                  <Icon
                                    className="size-20"
                                    icon="lucide:plus"
                                  />
                                </button>
                              ) : (
                                <div />
                              ))}
                            <div className="flex items-center justify-end gap-x-15">
                              {isEditor &&
                                (isEditing || isAdding ? (
                                  <>
                                    <button
                                      className="inline-flex cursor-pointer items-center gap-x-4 text-error-redx"
                                      onClick={
                                        isAdding
                                          ? toggleIsAdding
                                          : handleCancelEdit
                                      }
                                    >
                                      <span className="text-15 font-semibold">
                                        Cancel
                                      </span>
                                      <Icon
                                        className="size-20"
                                        icon="lucide:x"
                                      />
                                    </button>
                                    <button
                                      className={`inline-flex items-center gap-x-4 ${
                                        isSubmitting
                                          ? 'text-placeholder-redx'
                                          : 'text-blue-redx'
                                      } cursor-pointer`}
                                      disabled={isSubmitting}
                                      id="btn-edit-idea-expansion"
                                      onClick={handleSave}
                                    >
                                      <span className="text-15 font-semibold">
                                        Save
                                      </span>
                                      <Icon
                                        className="size-20"
                                        icon="lucide:save"
                                      />
                                    </button>
                                  </>
                                ) : (
                                  <EditButton toggleEditing={toggleIsEditing} />
                                ))}
                            </div>
                          </div>
                          <div className="flex w-full pb-60">
                            {!isLoading && sources && (
                              <SourcesList sources={sources} />
                            )}

                            {isLoading && (
                              <div className="inline-flex gap-10">
                                {Array.from({ length: 3 }).map((_, index) => (
                                  <div
                                    key={index}
                                    className="full flex h-65 min-w-363 animate-pulse items-center gap-x-12 rounded-10 border-1 border-soft-purple-redx bg-soft-purple-redx px-15 py-12"
                                  />
                                ))}
                              </div>
                            )}
                          </div>
                        </TabPanels>
                      </TabGroup>
                    </div>
                  </TabPanel>
                ))
              ) : (
                <TabPanel>
                  <div className="pt-5">
                    <div className="h-20 w-full animate-pulse rounded-full bg-soft-purple-redx" />
                    <div className="mt-20" />

                    <TabGroup
                      className="flex w-full flex-row gap-24"
                      onChange={setActiveCategoryTab}
                      selectedIndex={activeCategoryTab}
                    >
                      <TabList className="flex w-2/3 flex-col items-start justify-start border-r-1 border-r-stroke-redx">
                        {Array.from({ length: 3 }).map((_, index) => {
                          return (
                            <Tab
                              key={index}
                              ref={(el: HTMLElement | null) => {
                                if (el) {
                                  (
                                    tabsCategoryTypeRef.current as HTMLElement[]
                                  )[index] = el;
                                }
                              }}
                              className="w-full py-10 text-start text-16 font-normal leading-20 text-black-redx data-[selected]:border-blue-redx data-[selected]:font-bold data-[selected]:text-blue-redx"
                            >
                              <p className="font-normal">
                                <div className="h-20 w-2/3 animate-pulse rounded-full bg-soft-purple-redx" />
                              </p>
                              <p className="mt-10 text-12 leading-14 text-grey-redx">
                                <div className="h-20 w-2/5 animate-pulse rounded-full bg-soft-purple-redx" />
                              </p>
                            </Tab>
                          );
                        })}
                      </TabList>
                      <TabPanels className="flex w-4/5 grow flex-col">
                        <div className="-mt-24">
                          <SliderContainer
                            goToIndex={goToIndex}
                            isAdding={false}
                            isEditing={false}
                            length={3}
                            onActiveIndex={(index) => setActiveIndex(index)}
                            selectedIndexes={[]}
                          >
                            {Array.from({ length: 3 }).map((_, index) => (
                              <div className="my-24">
                                <Card
                                  key={index}
                                  id={-1}
                                  index={-1}
                                  isEditor={false}
                                  isFetching
                                  item={{
                                    idea_title: '',
                                    campaign_example: '',
                                    campaign_metrics: '',
                                    idea_description: '',
                                    preferred_platform: '',
                                    preferred_influencer: '',
                                    reason_product_brand: '',
                                    reason_audience_archetype: '',
                                    idea_expansion_number: 0,
                                  }}
                                  project={project}
                                  selectedFramework=""
                                  user={user}
                                />
                              </div>
                            ))}
                          </SliderContainer>
                        </div>

                        <div className="mb-35 flex w-full items-center justify-between border-t-1 border-stroke-redx pt-12">
                          {isEditor &&
                            (!isAdding && !isEditing ? (
                              <button
                                className="inline-flex gap-x-4 text-15 font-semibold text-blue-redx"
                                onClick={toggleIsAdding}
                              >
                                Add {handleTitleFunnel(selectedFunnel)}{' '}
                                Touchpoint Manually
                                <Icon className="size-20" icon="lucide:plus" />
                              </button>
                            ) : (
                              <div />
                            ))}
                          <div className="flex items-center justify-end gap-x-15">
                            {isEditor &&
                              (isEditing || isAdding ? (
                                <>
                                  <button
                                    className="inline-flex cursor-pointer items-center gap-x-4 text-error-redx"
                                    onClick={
                                      isAdding
                                        ? toggleIsAdding
                                        : handleCancelEdit
                                    }
                                  >
                                    <span className="text-15 font-semibold">
                                      Cancel
                                    </span>
                                    <Icon className="size-20" icon="lucide:x" />
                                  </button>
                                  <button
                                    className="inline-flex cursor-pointer items-center gap-x-4 text-blue-redx"
                                    onClick={() => {}}
                                  >
                                    <span className="text-15 font-semibold">
                                      Save
                                    </span>
                                    <Icon
                                      className="size-20"
                                      icon="lucide:save"
                                    />
                                  </button>
                                </>
                              ) : (
                                <EditButton toggleEditing={toggleIsEditing} />
                              ))}
                          </div>
                        </div>
                        <div className="flex w-full pb-60">
                          {!isLoading && sources && (
                            <SourcesList sources={sources} />
                          )}

                          {isLoading && (
                            <div className="inline-flex gap-10">
                              {Array.from({ length: 3 }).map((_, index) => (
                                <div
                                  key={index}
                                  className="full flex h-65 min-w-363 animate-pulse items-center gap-x-12 rounded-10 border-1 border-soft-purple-redx bg-soft-purple-redx px-15 py-12"
                                />
                              ))}
                            </div>
                          )}
                        </div>
                      </TabPanels>
                    </TabGroup>
                  </div>
                </TabPanel>
              )}
            </TabPanels>
          </TabGroup>
        </div>
      )}
    </>
  );
};

export default Index;
