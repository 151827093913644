/* eslint-disable no-nested-ternary */
import { zodResolver } from '@hookform/resolvers/zod';
import { Icon } from '@iconify/react/dist/iconify.js';
import { Fragment, useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import RegenerateModal from '@/Components/Modals/RegenerateModal';
import ViewInfoModal from '@/Components/Modals/ViewInfoModal';
import EditButton from '@/Components/Projects/EditButton';
import {
  saveInformation,
  saveRouteNext,
  saveRoutePrev,
  saveSubInformation,
  setEnableHistoryStatus,
} from '@/Context/actions/projectActions';
import { ProjectContext } from '@/Context/ProjectContext';
import type { RouteOption } from '@/Context/reducer/projectReducer';
import {
  useIdeaCreative,
  useUpdateCreative,
} from '@/Hooks/react-query/idea-creative';
import useHistoryStatus from '@/Hooks/react-query/useHistoryStatus';
import useUser from '@/Hooks/react-query/useUser';
import useRolesBadge from '@/Hooks/useRolesBadge';
import type { CreativeIdea } from '@/Types/creative-idea/creative-idea';
import type { Schema } from '@/Types/creative-idea/schema';
import { mainSchema } from '@/Types/creative-idea/schema';
import type { ProjectProps } from '@/Types/projects';
import { triggerGTMEvent } from '@/Utils/gtm';

import CardInput from './Partials/CardInput';

interface CreativeIdeaProps {
  project: ProjectProps;
}
const Index = ({ project }: CreativeIdeaProps) => {
  const navigate = useNavigate();
  const [showViewInfoModal, setShowViewInfoModal] = useState(false);
  const [, dispatch] = useContext(ProjectContext);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [disabledSave, setDisabledSave] = useState<boolean>(true);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [status, setStatus] = useState<string>('new');
  const { data, refetch, isPending, isFetching } = useIdeaCreative(
    project.slug,
  );
  const { mutate, isPending: isSubmitting } = useUpdateCreative();
  const { isIdeaAlignment, isPending: isPendingHistory } =
    useHistoryStatus(project);

  const toggleEditing = () => setIsEditing(!isEditing);

  const { data: user } = useUser();
  const { roles } = useRolesBadge(project, user);
  const isEditor = roles.includes('Owner') || roles.includes('Creative');
  const [parsedData, setParsedData] = useState<CreativeIdea>({
    title: '',
    description: '',
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm<Schema>({
    defaultValues: parsedData,
    resolver: zodResolver(mainSchema),
    mode: 'onChange',
  });

  const handleCancelEditSection = () => {
    toggleEditing();
    reset(parsedData);
  };

  const onSubmit = (data: Schema) => {
    mutate(
      { projectSlug: project.slug, payload: data },
      {
        onSuccess: async () => {
          triggerGTMEvent({
            event: `Edit Creative Idea`,
            eventCategory: `Button Edit Creative Idea Click`,
            eventAction: 'Click',
            eventLabel: 'Creative Idea',
            userId: user.email,
            data,
          });

          refetch();
          isEditing && toggleEditing();

          if (!isPendingHistory && !isIdeaAlignment.isQuerying) {
            if (isIdeaAlignment.isRegenerated || !isIdeaAlignment.status) {
              navigate(`/${project.slug}/idea-alignment`);
            }
          }
        },
      },
    );
  };

  const routeOptionValue: RouteOption = {
    label: 'Generate Idea Alignment',
    isActive: true,
    isGenerate: !!isEditor,
    isDisabled: disabledSave,
    onClick: () => {},
  };

  useEffect(() => {
    dispatch(saveRouteNext(routeOptionValue));
    dispatch(
      saveRoutePrev({
        label: 'Back',
        isActive: true,
        onClick: () => navigate(`/${project.slug}/key-touch-point`),
      }),
    );
    dispatch(saveInformation(''));
    dispatch(saveSubInformation(''));
  }, [dispatch, navigate, project.slug]);

  useEffect(() => {
    if (data?.status) {
      if (data.data) {
        const { title, description } = data.data;

        reset({
          title,
          description,
        });

        setParsedData({
          title: title ?? '',
          description: description ?? '',
        });

        setIsEditing(false);
        setStatus(data?.is_idea_alignment_exists ? 'next' : 'generate');
      } else {
        isEditor && setIsEditing(true);
      }
    }
  }, [data]);

  useEffect(() => {
    let labelButton = isIdeaAlignment.status
      ? 'Next to Idea Alignment'
      : 'Generate Idea Alignment';

    let isGenerate = isEditor && !isIdeaAlignment.status;
    if (isIdeaAlignment.isRegenerated) {
      labelButton = 'Regenerate Idea Alignment';
      isGenerate = true;
    }

    if (!isEditor) {
      labelButton = 'Next to Idea Alignment';
      isGenerate = false;
      routeOptionValue.isInactive =
        !isIdeaAlignment.status || isIdeaAlignment.isQuerying;
      routeOptionValue.isDisabled = false;
    }

    routeOptionValue.label = labelButton;
    routeOptionValue.isGenerate = isGenerate;
    routeOptionValue.onClick = async () => {
      if (!isEditor) {
        if (!isIdeaAlignment.status) {
          setShowViewInfoModal(true);
          return;
        }

        navigate(`/${project.slug}/idea-alignment`);
        return;
      }

      if (isEditor && isIdeaAlignment.isQuerying) {
        dispatch(
          saveSubInformation(
            `{text-error-redx} Please wait, idea alignment is still generating`,
          ),
        );

        setTimeout(() => {
          dispatch(saveSubInformation(''));
        }, 3000);

        return;
      }
      if (status === 'next' && !isIdeaAlignment.isRegenerated) {
        navigate(`/${project.slug}/idea-alignment`);
      } else {
        await handleSubmit(onSubmit)();
      }
    };

    if (status === 'next' && isEditing) {
      routeOptionValue.isDisabled = true;
    }

    dispatch(
      saveRouteNext({
        ...routeOptionValue,
      }),
    );
  }, [status, disabledSave, isEditor, isIdeaAlignment, isEditing]);

  useEffect(() => {
    setDisabledSave(!isValid || isSubmitting);
    dispatch(
      saveRouteNext({
        ...routeOptionValue,
        isDisabled: !isValid || isSubmitting,
      }),
    );
  }, [isValid, isSubmitting]);

  useEffect(() => {
    dispatch(setEnableHistoryStatus(true));
  }, []);

  const isNew = status === 'new';

  return (
    <>
      <div className="size-full">
        <div className="sticky top-56 z-20 w-full bg-white py-16">
          <h1 className="mb-8 text-25 font-bold leading-none">Creative Idea</h1>
          <span className="text-15 font-normal text-grey-redx">
            Please provide your initial ideas, which will be aligned with the
            strategy and expanded based on your selected touch points.
          </span>
        </div>
        <div className="mt-35 flex flex-col gap-24">
          <Controller
            control={control}
            name="title"
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <CardInput
                  defaultValue={parsedData.title}
                  error={error?.message}
                  isEditing={isEditing}
                  isFetching={isPending || isFetching}
                  onChange={onChange}
                  placeholder="Input your idea title here"
                  rows={1}
                  showTitle
                  title="Idea Title"
                  value={value}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="description"
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <CardInput
                  defaultValue={parsedData.description}
                  error={error?.message}
                  isEditing={isEditing}
                  isFetching={isPending || isFetching}
                  onChange={onChange}
                  placeholder="Input your idea description here"
                  rows={3}
                  showTitle
                  title="Idea Description"
                  value={value}
                />
              );
            }}
          />
        </div>
        {isEditor && !isNew && (
          <div className="mt-16 flex w-full items-center justify-end gap-x-15">
            {isPending || isFetching ? (
              <div className="mb-14 h-20 w-65 animate-pulse rounded-full  bg-soft-purple-redx" />
            ) : isEditing ? (
              <>
                <button
                  className="inline-flex cursor-pointer items-center gap-x-4 text-error-redx"
                  onClick={handleCancelEditSection}
                >
                  <span className="text-15 font-semibold">Cancel</span>
                  <Icon className="size-20" icon="lucide:x" />
                </button>
                <button
                  className={`inline-flex items-center gap-x-4 ${
                    disabledSave ? 'text-gray-500' : 'text-blue-redx'
                  } cursor-pointer`}
                  disabled={disabledSave}
                  id="btn-edit-creative-idea"
                  onClick={async () => handleSubmit(onSubmit)()}
                >
                  <span className="text-15 font-semibold">Save</span>
                  <Icon className="size-20" icon="lucide:save" />
                </button>
              </>
            ) : (
              <EditButton toggleEditing={toggleEditing} />
            )}
          </div>
        )}
      </div>

      <ViewInfoModal
        isOpen={showViewInfoModal}
        onClose={() => setShowViewInfoModal(false)}
      />
      <RegenerateModal
        handleSubmit={async () => {
          setIsModalOpen(false);
          await handleSubmit(onSubmit)();
        }}
        isOpen={isModalOpen}
        isRegenerating={isSubmitting}
        onClose={() => {
          setIsModalOpen(false);
        }}
        section="idea-alignment"
      />
    </>
  );
};

export default Index;
