import { Icon } from '@iconify/react';
import { useQueryClient } from '@tanstack/react-query';
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import GenerateLoading from '@/Components/GenerateLoading';
import RegenerateInfoModal from '@/Components/Modals/RegenerateInfoModal';
import RegenerateModal from '@/Components/Modals/RegenerateModal';
import ViewInfoModal from '@/Components/Modals/ViewInfoModal';
import EditButton from '@/Components/Projects/EditButton';
import RegenerateButton from '@/Components/Projects/RegenerateButton';
import SliderContainer from '@/Components/Projects/SliderContainer';
import SourcesList from '@/Components/Projects/SourcesList';
import ErrorLLM from '@/Components/Toast/ErrorLLM';
import {
  saveInformation,
  saveRouteNext,
  saveRoutePrev,
  saveSubInformation,
  setEnableHistoryStatus,
} from '@/Context/actions/projectActions';
import { useGenerate } from '@/Context/hooks/useGenerate';
import { ProjectContext } from '@/Context/ProjectContext';
import type { RouteOption } from '@/Context/reducer/projectReducer';
import { useUpdateArchetype } from '@/Hooks/react-query/audience-archetype';
import useArchetypes from '@/Hooks/react-query/audience-archetype/useArchetypes';
import useDifferentArchetypes from '@/Hooks/react-query/audience-archetype/useDifferentArchetypes';
import useHistoryStatus from '@/Hooks/react-query/useHistoryStatus';
import useUser from '@/Hooks/react-query/useUser';
import useRolesBadge from '@/Hooks/useRolesBadge';
import { fetcher } from '@/Services/axios';
import type {
  Archetype,
  SubsectionArchetypeType,
} from '@/Types/audience-archetype';
import type { Schema } from '@/Types/audience-archetype/schema';
import type { ProjectProps, SourcesType } from '@/Types/projects';
import type { SectionList } from '@/Types/tabs';
import { progressPortion } from '@/Utils/dispatcher';
import { triggerGTMEvent } from '@/Utils/gtm';

import type { CardHandles } from './Partials/Card';
import Card from './Partials/Card';
import ConfirmationModal from './Partials/ConfirmationModal';

const AudienceArchetypeIndex: React.FC<{ project: ProjectProps }> = ({
  project,
}) => {
  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const [state, dispatch] = useContext(ProjectContext);
  const [isRegenerating, setIsReGenerating] = useState(false);
  const [isRefetchDiffArch, setIsRefetchDiffArch] = useState<string>('idle');
  const [isEditing, setIsEditing] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showAlertGenerateModal, setShowAlertGenerateModal] = useState(false);
  const [showRegenerateModal, setShowRegenerateModal] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [parsedData, setParsedData] = useState<SubsectionArchetypeType[]>([]);
  const [editedData, setEditedData] = useState<SubsectionArchetypeType[]>([]);
  const [sources, setSources] = useState<SourcesType[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number[] | undefined>();
  const [showViewInfoModal, setShowViewInfoModal] = useState(false);
  const [totalSelectedArchetypes, setTotalSelectedArchetypes] =
    useState<number>(0);
  const [totalArchetypes, setTotalArchetypes] = useState<number>(0);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [totalRegenerate, setTotalRegenerate] = useState<number>(0);
  const [isGenerate, setIsGenerate] = useState(false);
  const [isWaitingTargetAudience, setIsWaitingTargetAudience] = useState(false);
  const [goToIndex, setGoToIndex] = useState<number | undefined>(undefined);
  const cardRefs = useRef<(CardHandles | null)[]>([]);
  const { mutate, isPending } = useUpdateArchetype();

  const { data: user } = useUser();

  const { roles } = useRolesBadge(project, user);
  const {
    handleGenerateAudienceArchetype,
    handleGenerateKeyInsight,
    handleGenerateOpportunityAmongCompetition,
  } = useGenerate(dispatch);
  const {
    data,
    isLoading,
    refetch,
    isRefetching,
    isPending: isePendingRefetch,
  } = useArchetypes(project);

  const {
    isAudienceArchetypeComplete,
    isTargetAudienceAudienceInsight,
    isTargetAudienceBehavioralTrend,
    isTargetAudienceMediaConsumptionPattern,
    isTargetAudiencePerceptionAnalysis,
    isOpportunityAmongCompetitionComplete,
    isKeyInsightsComplete,
    isUpdateState: isPendingHistoryStatus,
  } = useHistoryStatus(project, state.isEnabledHistoryStatus);

  const isEditor = roles.includes('Owner') || roles.includes('Strategist');

  const toggleIsEditing = () => setIsEditing(!isEditing);
  const toggleIsAdding = () => setIsAdding(!isAdding);
  const {
    data: differentArchetypes,
    refetch: refetchDiff,
    isRefetching: isPendingDiffArch,
  } = useDifferentArchetypes(project);

  const routeOptionValue: RouteOption = {
    label: 'Generate Key Insight',
    isActive: true,
    isGenerate: true,
    isInactive: true,
    isDisabled: false,
  };

  const handleCancelEdit = () => {
    setParsedData(editedData);
    toggleIsEditing();
  };

  const handleFetchSubmit = async (
    payload: Schema,
    id: number,
    index: number,
  ) => {
    const projectSlug = project.slug;

    mutate(
      { payload: payload as unknown as Archetype, id, projectSlug },
      {
        onSuccess: () => {
          triggerGTMEvent({
            event: `${id === -1 ? 'Add' : 'Update'} Audience Archetype`,
            eventCategory: `Button ${id === -1 ? 'Add' : 'Update'} Audience Archetype  Click`,
            eventAction: 'Click',
            eventLabel: 'Audience Archetype',
            userId: user.email,
            data: payload,
          });

          if (isAdding) {
            setTimeout(() => {
              setGoToIndex(index);
            }, 1000);
          }

          isAdding && toggleIsAdding();
          isEditing && toggleIsEditing();

          setTimeout(() => {
            refetch();
          }, 700);
        },
      },
    );
  };

  const handleRefetchSelectedData = async () => {
    queryClient.resetQueries({
      queryKey: ['keyInsights', project.slug],
      exact: true,
    });

    dispatch(
      saveRouteNext({
        ...routeOptionValue,
        label: isKeyInsightsComplete.isRegenerated
          ? 'Regenerate Key Insight'
          : 'Generate Key Insight',
        isDisabled: true,
      }),
    );
    const { data } = await fetcher.get(
      `/${project.slug}/audience-archetype/check-regenerate`,
    );
    dispatch(
      saveRouteNext({
        ...routeOptionValue,
        label: isKeyInsightsComplete.isRegenerated
          ? 'Regenerate Key Insight'
          : 'Generate Key Insight',
        isDisabled: false,
      }),
    );
    if (data?.data.is_show_pop_up || isKeyInsightsComplete.isRegenerated) {
      setShowRegenerateModal(
        data.data.is_show_pop_up || isKeyInsightsComplete.isRegenerated,
      );
      return;
    }

    navigate(`/${project.slug}/key-insight`);
  };

  const handleRegenerateButton = async (data: { prompt: string }) => {
    triggerGTMEvent({
      event: `Generate More Audience Archetype`,
      eventCategory: `Generate More Audience Archetype Click`,
      eventAction: 'Click',
      eventLabel: 'Audience Archetype',
      userId: user.email,
      data: data.prompt,
    });
    dispatch(saveSubInformation(''));
    setParsedData([]);
    setEditedData([]);
    setIsGenerate(true);
    setSelectedIndex([]);
    await handleGenerateAudienceArchetype()(project.slug, data.prompt);
  };

  const onSelectSuccess = (isShowPopUp: boolean) => {
    setShowAlertGenerateModal(isShowPopUp);
    refetch();
  };

  const handleSubmit = () => {
    cardRefs.current.forEach((ref, index) => {
      if (ref && index === activeIndex) {
        ref.submitForm();
      }
    });
  };

  const handleRegenerate = async () => {
    dispatch(saveInformation(''));
    dispatch(saveSubInformation(''));
    setIsReGenerating(true);
    triggerGTMEvent({
      event: `Generate Key Insight`,
      eventCategory: `Generate Key Insight Click`,
      eventAction: 'Click',
      eventLabel: 'Key Insight',
      userId: user.email,
      data: project.slug,
    });
    await handleGenerateKeyInsight()(project.slug, null);

    if (
      isOpportunityAmongCompetitionComplete.status &&
      (differentArchetypes?.is_different_archetype.opportunities ||
        isOpportunityAmongCompetitionComplete.isRegenerated)
    ) {
      triggerGTMEvent({
        event: `Generate Opportunity Among Competition`,
        eventCategory: `Generate Opportunity Among Competition Click`,
        eventAction: 'Click',
        eventLabel: 'Opportunity Among Competition',
        userId: user.email,
        data: project.slug,
      });
      await handleGenerateOpportunityAmongCompetition()(project.slug, null);
    }

    setIsReGenerating(false);
    navigate(`/${project.slug}/key-insight`);
  };

  useEffect(() => {
    dispatch(saveSubInformation(''));
    const totalSelectedArchetypes =
      data?.data?.data?.total_selected_archetypes || 0;
    const totalArchetypes = data?.data?.data?.total_archetypes || 0;

    setTotalSelectedArchetypes(totalSelectedArchetypes);
    setTotalArchetypes(totalArchetypes);

    if (data?.data?.data) {
      const {
        archetypes,
        sources,
        total_regenerate: totalRegenerate,
        is_querying: isQuerying,
      } = data.data.data;

      if (isQuerying) {
        setIsGenerate(true);

        if (
          !isLoading &&
          !isPendingHistoryStatus &&
          isAudienceArchetypeComplete.status === false
        ) {
          if (!isAudienceArchetypeComplete.isQuerying) {
            triggerGTMEvent({
              event: `Generate Audience Archetype`,
              eventCategory: `Generate Audience Archetype Click`,
              eventAction: 'Click',
              eventLabel: 'Audience Archetype',
              userId: user.email,
              data: project.slug,
            });
            handleGenerateAudienceArchetype()(project.slug);
          }
        }

        setParsedData([]);
        setEditedData([]);
        setSources([]);
        setTotalRegenerate(0);

        return;
      }

      if (
        archetypes.length === 0 &&
        !isLoading &&
        !isPendingHistoryStatus &&
        isAudienceArchetypeComplete.status === false
      ) {
        setIsGenerate(true);
        if (!isAudienceArchetypeComplete.isQuerying)
          handleGenerateAudienceArchetype()(project.slug);

        return;
      }

      if (archetypes.length > 0) {
        setParsedData(archetypes);
        setEditedData(archetypes);
        setSources(sources);
        setTotalRegenerate(totalRegenerate);
        setIsGenerate(false);
      }
    }
  }, [data, isAudienceArchetypeComplete, isLoading, isPendingHistoryStatus]);

  useEffect(() => {
    if (!isAudienceArchetypeComplete.status) {
      dispatch(saveInformation(`0 from 0 archetype selected`));
    }

    if (isAudienceArchetypeComplete.isError) {
      toast.custom((t) => <ErrorLLM t={t} />);
    }

    if (
      !isPendingHistoryStatus &&
      !isePendingRefetch &&
      !isRefetching &&
      isAudienceArchetypeComplete.isQuerying
    ) {
      setIsGenerate(true);
    }
  }, [
    isAudienceArchetypeComplete,
    isPendingHistoryStatus,
    isePendingRefetch,
    isRefetching,
  ]);

  useEffect(() => {
    !isAudienceArchetypeComplete.isQuerying &&
      !isPendingHistoryStatus &&
      refetch();

    isAudienceArchetypeComplete.isQuerying &&
      !isPendingHistoryStatus &&
      setIsGenerate(true);
  }, [isAudienceArchetypeComplete, isPendingHistoryStatus, isGenerate]);

  useEffect(() => {
    setIsWaitingTargetAudience(
      isTargetAudienceAudienceInsight.status &&
        isTargetAudienceBehavioralTrend.status &&
        isTargetAudienceMediaConsumptionPattern.status &&
        isTargetAudiencePerceptionAnalysis.status,
    );
  }, [
    isTargetAudienceAudienceInsight,
    isTargetAudienceBehavioralTrend,
    isTargetAudienceMediaConsumptionPattern,
    isTargetAudiencePerceptionAnalysis,
  ]);

  useEffect(() => {
    const handleGenerate = async () => {
      try {
        if (
          !isAudienceArchetypeComplete.status ||
          isGenerate ||
          isAudienceArchetypeComplete.isQuerying
        ) {
          dispatch(
            saveSubInformation(
              `{text-error-redx} Please wait, audience archetype is still generating`,
            ),
          );
          return;
        }

        if (!isWaitingTargetAudience) {
          dispatch(
            saveSubInformation(
              `{text-error-redx} Please wait, target audience is still generating`,
            ),
          );
          return;
        }

        const { data } = await fetcher.get(
          `/${project.slug}/audience-archetype/check-objective`,
        );

        if (data && !data.data.have_objective) {
          dispatch(
            saveSubInformation(
              `{text-error-redx} Please fill objective in submission first`,
            ),
          );
          return;
        }

        if (
          selectedIndex?.length === 1 &&
          (differentArchetypes?.is_different_archetype.key_insight ||
            isKeyInsightsComplete.isRegenerated)
        ) {
          setShowConfirmationModal(true);
        } else {
          handleRefetchSelectedData();
        }
      } catch (error) {
        // console.error('Error in handleGenerate:', error);
      } finally {
        setIsRefetchDiffArch('idle');
      }
    };

    if (isRefetchDiffArch === 'done') {
      (async () => {
        await handleGenerate();
      })();
    }
  }, [
    isGenerate,
    differentArchetypes?.is_different_archetype.key_insight,
    isPendingDiffArch,
    selectedIndex,
    isRefetchDiffArch,
    isKeyInsightsComplete,
    isAudienceArchetypeComplete,
  ]);

  useEffect(() => {
    dispatch(
      saveRoutePrev({
        label: 'Back',
        isActive: true,
        onClick: () => navigate(`/${project.slug}/target-audience`),
      }),
    );
  }, []);

  useEffect(() => {
    if (parsedData?.length) {
      const selectedDataWithIndex = parsedData
        .map((item, index) => ({ item, index })) // Map to array of objects with item and index
        .filter(({ item }) => item.is_selected === 1);

      const indices = selectedDataWithIndex.map(({ index }) => index);
      setSelectedIndex(indices);
    }
  }, [parsedData]);

  useEffect(() => {
    if (isEditor) {
      if (
        isKeyInsightsComplete.isRegenerated ||
        differentArchetypes?.is_different_archetype.key_insight
      ) {
        routeOptionValue.label = 'Regenerate Key Insight';
        routeOptionValue.isGenerate = true;
      }

      if (
        isKeyInsightsComplete.status &&
        !isKeyInsightsComplete.isRegenerated &&
        !differentArchetypes?.is_different_archetype.key_insight
      ) {
        routeOptionValue.label = 'Next to Key Insight';
        routeOptionValue.isGenerate = false;
      }

      if (
        !isKeyInsightsComplete.status &&
        !isKeyInsightsComplete.isRegenerated
      ) {
        routeOptionValue.label = 'Generate Key Insight';
        routeOptionValue.isGenerate = true;
      }

      routeOptionValue.isInactive =
        !isAudienceArchetypeComplete.status ||
        isAudienceArchetypeComplete.isQuerying
          ? true
          : totalSelectedArchetypes === 0;
    } else {
      routeOptionValue.label = 'Next to Key Insight';
      routeOptionValue.isGenerate = false;

      routeOptionValue.isInactive =
        !isKeyInsightsComplete.status ||
        isKeyInsightsComplete.isQuerying ||
        differentArchetypes?.is_different_archetype.key_insight;
    }

    routeOptionValue.onClick = async () => {
      dispatch(saveSubInformation(''));
      if (state.routeNext.isDisabled) return;

      if (
        !isAudienceArchetypeComplete.status ||
        isAudienceArchetypeComplete.isQuerying
      ) {
        dispatch(
          saveSubInformation(
            `{text-error-redx} Please wait, audience archetype is still generating`,
          ),
        );
        return;
      }

      if (
        !isEditor &&
        (!isKeyInsightsComplete.status ||
          differentArchetypes?.is_different_archetype.key_insight)
      ) {
        setShowViewInfoModal(true);
        return;
      }

      if (isEditor && isKeyInsightsComplete.isQuerying) {
        dispatch(
          saveSubInformation(
            `{text-error-redx} Please wait, key insight is still generating`,
          ),
        );

        setTimeout(() => {
          dispatch(saveSubInformation(''));
        }, 3000);
        return;
      }

      if (isEditor && (isAdding || isEditing)) {
        dispatch(saveSubInformation(`{text-error-redx} Please do save first`));

        setTimeout(() => {
          dispatch(saveSubInformation(''));
        }, 3000);
        return;
      }

      if (totalSelectedArchetypes === 0) {
        dispatch(
          saveSubInformation(
            `{text-error-redx} Please select audience archetype first`,
          ),
        );
        return;
      }

      if (totalSelectedArchetypes === 0) {
        dispatch(
          saveSubInformation(
            `{text-error-redx} Please select audience archetype first`,
          ),
        );
        return;
      }

      if (!routeOptionValue.isGenerate) {
        navigate(`/${project.slug}/key-insight`);
        return;
      }
      setIsRefetchDiffArch('fetching');
      dispatch(saveRouteNext({ ...routeOptionValue, isDisabled: true }));
      await refetchDiff();
      setIsRefetchDiffArch('done');
      dispatch(saveRouteNext({ ...routeOptionValue, isDisabled: false }));
    };

    if (isAdding || isEditing) {
      routeOptionValue.isInactive = true;
    }

    dispatch(
      saveRouteNext({
        ...routeOptionValue,
      }),
    );

    dispatch(
      saveInformation(
        `${totalSelectedArchetypes} from ${totalArchetypes} archetype selected`,
      ),
    );
  }, [
    differentArchetypes,
    isPendingHistoryStatus,
    totalSelectedArchetypes,
    isAudienceArchetypeComplete,
    isKeyInsightsComplete,
    showRegenerateModal,
    isEditor,
    isAdding,
    isEditing,
  ]);

  const sectionList: SectionList = {
    title: 'Audience Archetype',
    value: 'archetype',
    section: 'audience',
  };

  useEffect(() => {
    dispatch(setEnableHistoryStatus(!(isAdding || isEditing)));
  }, [isAdding, isEditing]);

  return (
    <>
      <Helmet>
        <title>Audience Archetype</title>
      </Helmet>
      {isGenerate ? (
        <GenerateLoading
          progress={progressPortion({
            progress: Number((state.audienceArchetype.progress / 2).toFixed(0)),
            isQuerying: isAudienceArchetypeComplete.isQuerying,
            isContentAvailable: parsedData?.length > 0,
          })}
          project={project}
          section={sectionList}
          showEmailNotification={isAudienceArchetypeComplete.isSendingEmail}
        />
      ) : (
        <div className="size-full">
          <div className="sticky top-56 z-20 mb-24 flex flex-row items-center justify-between gap-16 bg-white py-16 md:flex-col">
            <div className="flex grow flex-col">
              <h1 className="mb-8 text-25 font-bold leading-none">
                Audience Archetype
              </h1>
              <span className="text-1620 font-normal text-grey-redx">
                Please{' '}
                <span className="font-bold text-black-redx">
                  select one or more{' '}
                </span>{' '}
                audience archetypes, or click &apos;New Archetype&apos; to add a
                new one
              </span>
            </div>
            {isEditor && (
              <div className="flex items-center">
                <RegenerateButton
                  isGenerateMore
                  limit={totalRegenerate}
                  maxLimit={project.max_generated_data} // to do ganti ke max limit
                  onSubmit={handleRegenerateButton}
                  section="audience-archetype"
                />
              </div>
            )}
          </div>
          {!isLoading && !isAudienceArchetypeComplete.isQuerying ? (
            <SliderContainer
              goToIndex={goToIndex}
              isAdding={isAdding}
              isEditing={isEditing}
              length={parsedData.length}
              onActiveIndex={(index) => setActiveIndex(index)}
              selectedIndexes={selectedIndex}
            >
              {parsedData.map((archetype, index) => (
                <Card
                  key={index}
                  ref={(el) => (cardRefs.current[index] = el)}
                  archetype={archetype.archetype_content}
                  id={archetype.id}
                  index={index}
                  isEditing={isEditing && isEditor}
                  isEditor={isEditor}
                  isFetching={isRefetching}
                  isSelected={!!archetype.is_selected}
                  onSelectSuccess={onSelectSuccess}
                  onSubmitSuccess={handleFetchSubmit}
                  project={project}
                  totalArchetype={parsedData.length}
                  totalSelected={selectedIndex?.length ?? 0}
                  user={user}
                />
              ))}
              {isEditor && isAdding && (
                <Card
                  ref={(el) => (cardRefs.current[parsedData.length] = el)}
                  archetype={{
                    archetype_name: '',
                    demographics: '',
                    occupation: '',
                    lifestyle: '',
                    behavior: '',
                    needs_and_challenges: '',
                    potential_buying_motives: '',
                    current_trends: '',
                    source_of_information: '',
                    online_search_behavior: '',
                    purchase_frequency: '',
                    preferred_sales_channels: '',
                  }}
                  id={-1}
                  index={parsedData.length}
                  isEditing={isAdding}
                  isEditor={isEditor}
                  isSelected={false}
                  onSelectSuccess={() => {}}
                  onSubmitSuccess={handleFetchSubmit}
                  project={project}
                  totalArchetype={parsedData.length + 1}
                  totalSelected={selectedIndex?.length ?? 0}
                  user={user}
                />
              )}
            </SliderContainer>
          ) : (
            <Card
              ref={(el) => (cardRefs.current[parsedData.length] = el)}
              archetype={{
                archetype_name: '',
                demographics: '',
                occupation: '',
                lifestyle: '',
                behavior: '',
                needs_and_challenges: '',
                potential_buying_motives: '',
                current_trends: '',
                source_of_information: '',
                online_search_behavior: '',
                purchase_frequency: '',
                preferred_sales_channels: '',
              }}
              id={-1}
              index={-1}
              isEditing={isAdding}
              isEditor={isEditor}
              isFetching
              isSelected={false}
              onSelectSuccess={() => {}}
              onSubmitSuccess={() => {}}
              project={project}
              totalArchetype={parsedData.length + 1}
              totalSelected={selectedIndex?.length ?? 0}
              user={user}
            />
          )}

          <div className="mb-35 flex w-full items-center justify-between pt-12">
            {isEditor &&
              (!isAdding && !isEditing ? (
                <button
                  className="inline-flex gap-x-4 text-15 font-semibold text-blue-redx"
                  onClick={toggleIsAdding}
                >
                  Add Archetype Manually
                  <Icon className="size-20" icon="lucide:plus" />
                </button>
              ) : (
                <div />
              ))}
            <div className="flex items-center justify-end gap-x-15">
              {isEditor &&
                (isEditing || isAdding ? (
                  <>
                    <button
                      className="inline-flex cursor-pointer items-center gap-x-4 text-error-redx"
                      onClick={isAdding ? toggleIsAdding : handleCancelEdit}
                    >
                      <span className="text-15 font-semibold">Cancel</span>
                      <Icon className="size-20" icon="lucide:x" />
                    </button>
                    <button
                      className={`inline-flex items-center gap-x-4 ${
                        isPending ? 'text-gray-600' : 'text-blue-redx'
                      } cursor-pointer`}
                      disabled={isPending}
                      id="btn-edit-audience-archetype"
                      onClick={handleSubmit}
                    >
                      <span className="text-15 font-semibold">Save</span>
                      <Icon className="size-20" icon="lucide:save" />
                    </button>
                  </>
                ) : (
                  <EditButton
                    toggleEditing={() => {
                      const currData = editedData[activeIndex];
                      if (currData) {
                        toggleIsEditing();
                      }
                    }}
                  />
                ))}
            </div>
          </div>
          <div className="mr-1 flex w-full pb-60">
            {!isLoading && sources && <SourcesList sources={sources} />}
            {isLoading && (
              <div className="inline-flex gap-10">
                {Array.from({ length: 3 }).map((_, index) => (
                  <div
                    key={index}
                    className="full flex h-65 min-w-363 animate-pulse items-center gap-x-12 rounded-10 border-1 border-soft-purple-redx bg-soft-purple-redx px-15 py-12"
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      )}

      <ConfirmationModal
        handleGenerate={handleRefetchSelectedData}
        isOpen={showConfirmationModal}
        onClose={() => {
          setShowConfirmationModal(false);
        }}
      />

      <RegenerateInfoModal
        isOpen={showAlertGenerateModal}
        onClose={() => {
          setShowAlertGenerateModal(false);
        }}
      />

      <RegenerateModal
        handleSubmit={handleRegenerate}
        isOpen={showRegenerateModal}
        isRegenerating={isRegenerating}
        onClose={() => {
          setShowRegenerateModal(false);
          dispatch(saveRouteNext({ ...routeOptionValue, isDisabled: false }));
        }}
        section="audience-archetype"
      />

      <ViewInfoModal
        isOpen={showViewInfoModal}
        onClose={() => setShowViewInfoModal(false)}
      />
    </>
  );
};

export default AudienceArchetypeIndex;
