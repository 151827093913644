/* eslint-disable react/jsx-no-useless-fragment */
import { zodResolver } from '@hookform/resolvers/zod';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import GenerateLoading from '@/Components/GenerateLoading';
import RegenerateModal from '@/Components/Modals/RegenerateModal';
import ViewInfoModal from '@/Components/Modals/ViewInfoModal';
import ErrorLLM from '@/Components/Toast/ErrorLLM';
import {
  saveInformation,
  saveRouteNext,
  saveRoutePrev,
  saveSubInformation,
} from '@/Context/actions/projectActions';
import { useGenerate } from '@/Context/hooks/useGenerate';
import { ProjectContext } from '@/Context/ProjectContext';
import useDifferentArchetypes from '@/Hooks/react-query/audience-archetype/useDifferentArchetypes';
import { useUpdateOnePage } from '@/Hooks/react-query/one-page-strategy';
import useOnePageStrategy from '@/Hooks/react-query/one-page-strategy/useOnePageStrategy';
import useHistoryStatus from '@/Hooks/react-query/useHistoryStatus';
import useUser from '@/Hooks/react-query/useUser';
import useRolesBadge from '@/Hooks/useRolesBadge';
import type { OnePageStrategy } from '@/Types/one-page/one-page-strategy';
import type { Schema } from '@/Types/one-page/schema';
import { mainSchema } from '@/Types/one-page/schema';
import type { ProjectProps } from '@/Types/projects';
import type { SectionList } from '@/Types/tabs';
import { progressPortion } from '@/Utils/dispatcher';
import { triggerGTMEvent } from '@/Utils/gtm';

import CardInput from './Partials/CardInput';
import DownloadPDFButton from './Partials/DownloadPDFButton';
import SectionCard from './Partials/SectionCard';
import SelectingFunnel from './Partials/SelectingFunnel';

// Define Zod schema

const Index: React.FC<{ project: ProjectProps }> = ({ project }) => {
  const [state, dispatch] = useContext(ProjectContext);
  const [parsedData, setParsedData] = useState<OnePageStrategy>({
    campaign_objective: '',
    challenge: '',
    communication_task: '',
    selected_audience_archetypes: [],
  });
  const [isEditingCount, setIsEditingCount] = useState(0);

  const [isGenerate, setIsGenerate] = useState<boolean>(false);
  const { mutate, isPending } = useUpdateOnePage();

  const { handleGenerateOnePageStrategy, handleGenerateKeyTouchPoint } =
    useGenerate(dispatch);
  const [showRegenerateModal, setShowRegenerateModal] = useState(false);

  const {
    isOnePageStrategy,
    isKeyTouchPoint,
    isUpdateState: isFetchingHistoryStatus,
    isFetched: isFetchedHistory,
  } = useHistoryStatus(project);

  const { data: user } = useUser();
  const { roles } = useRolesBadge(project, user);

  const { data, isLoading, refetch } = useOnePageStrategy(project);
  const { data: differentArchetypes, isPending: isPendingDiffArch } =
    useDifferentArchetypes(project);

  const [disabledSave, setDisabledSave] = useState<boolean>(true);
  const [isRegeneratingModal, setIsRegeneratingModal] =
    useState<boolean>(false);
  const [modalFramework, setModalFramework] = useState<boolean>(false);
  const navigate = useNavigate();
  const isEditor = roles.includes('Owner') || roles.includes('Strategist');
  const [showViewInfoModal, setShowViewInfoModal] = useState(false);
  const { control, handleSubmit, reset, watch, setError, clearErrors } =
    useForm<Schema>({
      defaultValues: parsedData,
      resolver: zodResolver(mainSchema),
    });

  watch(async (data) => {
    const result = await mainSchema.safeParse(data);
    result.error?.errors.forEach((error) => {
      setError(error.path[0] as keyof OnePageStrategy, {
        message: error.message,
      });
    });
    if (result.success) {
      clearErrors();
      setDisabledSave(false);
    } else {
      setDisabledSave(true);
    }
  });

  useEffect(() => {
    if (
      !isPendingDiffArch &&
      !differentArchetypes?.is_different_archetype.one_pages &&
      data?.data
    ) {
      const {
        campaign_strategic_statement: campaignStrategicStatement,
        challenge,
        communication_task: communicationTask,
        selected_audience_archetypes: selectedAudienceArchetypes,
        is_querying: isQuerying,
      } = data.data;

      if (isQuerying) {
        setIsGenerate(true);
        setParsedData({
          campaign_objective: '',
          challenge: '',
          communication_task: '',
          selected_audience_archetypes: [],
        });
        return;
      }

      setParsedData({
        campaign_objective: campaignStrategicStatement,
        challenge,
        communication_task: communicationTask,
        selected_audience_archetypes: selectedAudienceArchetypes,
      });
      dispatch(saveSubInformation(''));
    }
  }, [data, differentArchetypes, isPendingDiffArch, isGenerate]);

  useEffect(() => {
    !isFetchingHistoryStatus && !isOnePageStrategy.isQuerying && refetch();
    isOnePageStrategy.isQuerying &&
      !isFetchingHistoryStatus &&
      setIsGenerate(true);
  }, [
    isOnePageStrategy,
    isFetchingHistoryStatus,
    isGenerate,
    differentArchetypes?.is_different_archetype.one_pages,
  ]);

  useEffect(() => {
    if (
      isOnePageStrategy.status &&
      !isOnePageStrategy.isQuerying &&
      isFetchedHistory &&
      parsedData?.challenge &&
      parsedData.communication_task
    ) {
      setTimeout(() => {
        setIsGenerate(false);
      }, 1000);
    }

    if (isOnePageStrategy.isError) {
      toast.custom((t) => <ErrorLLM t={t} />);
    }
  }, [
    isOnePageStrategy,
    parsedData,
    isFetchingHistoryStatus,
    isFetchedHistory,
  ]);

  useEffect(() => {
    if (
      !isPendingDiffArch &&
      differentArchetypes?.is_different_archetype.one_pages
    ) {
      if (
        !isGenerate &&
        !isFetchingHistoryStatus &&
        !isOnePageStrategy.isQuerying
      ) {
        setIsGenerate(true);
        setParsedData({
          campaign_objective: '',
          challenge: '',
          communication_task: '',
          selected_audience_archetypes: [],
        });

        dispatch(saveInformation(''));
        dispatch(saveSubInformation(''));
        triggerGTMEvent({
          event: `Generate One Page Strategy`,
          eventCategory: `Generate One Page Strategy Click`,
          eventAction: 'Click',
          eventLabel: 'One Page Strategy',
          userId: user.email,
          data: project.slug,
        });

        handleGenerateOnePageStrategy()(project.slug);
      }
    }
  }, [
    isGenerate,
    differentArchetypes,
    isOnePageStrategy,
    isFetchingHistoryStatus,
    isPendingDiffArch,
  ]);

  useEffect(() => {
    reset(parsedData);
  }, [parsedData]);

  useEffect(() => {
    dispatch(
      saveRoutePrev({
        label: 'Back',
        isActive: true,
        onClick: () => {
          navigate(`/${project.slug}/opportunity-among-competition`);
        },
      }),
    );
    dispatch(saveInformation(''));
  }, [dispatch, navigate, project.slug]);

  useEffect(() => {
    let label = 'Generate Key Touch Point';
    let isGenerateButton = true;
    let isInactive = true;
    if (isEditor) {
      isInactive = !!(
        isGenerate ||
        isOnePageStrategy.isQuerying ||
        !isOnePageStrategy.status
      );
      if (
        isKeyTouchPoint.status &&
        !isKeyTouchPoint.isRegenerated &&
        !differentArchetypes?.is_different_archetype.key_touch_points
      ) {
        label = 'Next to Key Touch Point';
        isGenerateButton = false;
      }

      if (
        isKeyTouchPoint.status &&
        (isKeyTouchPoint.isRegenerated ||
          differentArchetypes?.is_different_archetype.key_touch_points)
      ) {
        label = 'Regenerate Key Touch Point';
      }
    } else {
      label = 'Next to Key Touch Point';
      isGenerateButton = false;
      isInactive =
        !isKeyTouchPoint.status ||
        isKeyTouchPoint.isQuerying ||
        (differentArchetypes?.is_different_archetype.key_touch_points ?? false);
    }

    dispatch(saveSubInformation(''));

    if (isEditingCount > 0) {
      isInactive = true;
    }

    dispatch(
      saveRouteNext({
        label,
        isActive: true,
        isInactive,
        isGenerate: isGenerateButton,
        onClick: () => {
          if (
            !isOnePageStrategy.status ||
            isGenerate ||
            isOnePageStrategy.isQuerying
          ) {
            dispatch(
              saveSubInformation(
                `{text-error-redx} Please wait, one page strategy is still generating`,
              ),
            );
            return;
          }

          if (
            (!isKeyTouchPoint.status ||
              differentArchetypes?.is_different_archetype.key_touch_points) &&
            !isEditor
          ) {
            setShowViewInfoModal(true);
            return;
          }

          if (!isPendingDiffArch && isEditor) {
            if (isKeyTouchPoint.isQuerying) {
              dispatch(
                saveSubInformation(
                  `{text-error-redx} Please wait, key touch point is still generating`,
                ),
              );

              setTimeout(() => {
                dispatch(saveSubInformation(''));
              }, 3000);
              return;
            }

            if (isEditor && isEditingCount > 0) {
              dispatch(
                saveSubInformation(`{text-error-redx} Please do save first`),
              );

              setTimeout(() => {
                dispatch(saveSubInformation(''));
              }, 3000);
              return;
            }

            if (differentArchetypes?.is_different_archetype.key_touch_points) {
              setModalFramework(true);
              return;
            }
            if (isKeyTouchPoint.isRegenerated) {
              setShowRegenerateModal(true);
              return;
            }

            navigate(`/${project.slug}/key-touch-point`);
            return;
          }
          navigate(`/${project.slug}/key-touch-point`);
        },
      }),
    );
  }, [
    isEditor,
    dispatch,
    navigate,
    project.slug,
    isPendingDiffArch,
    differentArchetypes?.is_different_archetype.key_touch_points,
    isOnePageStrategy,
    isGenerate,
    isKeyTouchPoint,
    isEditingCount,
  ]);

  const handleCancelEditSection = () => {
    reset(parsedData);
  };

  const handleRegenerateKeyTouchPoint = async () => {
    setIsRegeneratingModal(true);
    await handleGenerateKeyTouchPoint()(project.slug, null);
    setIsRegeneratingModal(false);
    setShowRegenerateModal(false);
    navigate(`/${project.slug}/key-touch-point`);
  };

  const onSubmit = async (data: Schema) => {
    const projectSlug = project.slug;
    mutate(
      { payload: data, projectSlug },
      {
        onSuccess: () => {
          triggerGTMEvent({
            event: `Edit One Page Strategy`,
            eventCategory: `Button Edit One Page Strategy Click`,
            eventAction: 'Click',
            eventLabel: 'One Page Strategy',
            userId: user.email,
            data,
          });

          refetch();
        },
      },
    );
  };

  const sectionList: SectionList = {
    title: 'One Page Strategy',
    value: 'onepage',
    section: 'brand',
  };

  return (
    <>
      <Helmet>
        <title>One-Page Strategy</title>
      </Helmet>
      {isGenerate ? (
        <GenerateLoading
          progress={progressPortion({
            progress: Number((state.onePageStrategy.progress / 2).toFixed(0)),
            isQuerying: isOnePageStrategy.isQuerying,
            isContentAvailable: true,
          })}
          project={project}
          section={sectionList}
          showEmailNotification={isOnePageStrategy.isSendingEmail}
        />
      ) : (
        <div className="size-full">
          <div className="sticky top-56 z-20 flex items-center justify-between bg-white py-16">
            <div className="flex flex-col">
              <h1 className="mb-8 text-25 font-bold leading-none">
                One-Page Strategy
              </h1>
              <span className="text-15 font-normal text-grey-redx">
                Please review and edit this information, considering that the
                provided reference may not always be accurate.
              </span>
            </div>
            <div className="flex items-center">
              <DownloadPDFButton
                isFetching={isLoading || isOnePageStrategy.isQuerying}
                parsedData={parsedData}
                project={project}
              />
            </div>
          </div>
          <form
            className="flex w-full flex-col gap-y-35 pb-60"
            onSubmit={handleSubmit(onSubmit)}
          >
            <SectionCard
              cols={1}
              disabledSave={disabledSave || isPending}
              initialEditState={
                parsedData.campaign_objective?.trim()?.length === 0
              }
              isEditingCount={isEditingCount}
              isEditor={isEditor}
              isFetching={isLoading || isOnePageStrategy.isQuerying}
              onCancelEdit={handleCancelEditSection}
              onSubmitEdit={handleSubmit(onSubmit)}
              setIsEditingCount={setIsEditingCount}
              title="Campaign Objective"
            >
              {({ isEditing }) => (
                <Controller
                  control={control}
                  name="campaign_objective"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <CardInput
                        defaultValue={parsedData.campaign_objective}
                        error={error?.message}
                        isEditing={isEditing}
                        isFetching={isLoading || isOnePageStrategy.isQuerying}
                        onChange={onChange}
                        placeholder="Input the campaign objective here"
                        showTitle={false}
                        title="Campaign Objective"
                        value={value}
                      />
                    );
                  }}
                />
              )}
            </SectionCard>

            <SectionCard
              cols={1}
              disabledSave={disabledSave || isPending}
              isEditingCount={isEditingCount}
              isEditor={isEditor}
              isFetching={isLoading || isOnePageStrategy.isQuerying}
              onCancelEdit={handleCancelEditSection}
              onSubmitEdit={handleSubmit(onSubmit)}
              setIsEditingCount={setIsEditingCount}
              title="Challenge"
            >
              {({ isEditing }) => (
                <Controller
                  control={control}
                  name="challenge"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <CardInput
                        defaultValue={parsedData.challenge}
                        error={error?.message}
                        isEditing={isEditing}
                        isFetching={isLoading || isOnePageStrategy.isQuerying}
                        onChange={onChange}
                        placeholder="Input challenge here"
                        showTitle={false}
                        title="Challenge"
                        value={value}
                      />
                    );
                  }}
                />
              )}
            </SectionCard>

            <SectionCard
              cols={1}
              disabledSave={disabledSave || isPending}
              isEditingCount={isEditingCount}
              isEditor={isEditor}
              isFetching={isLoading || isOnePageStrategy.isQuerying}
              onCancelEdit={handleCancelEditSection}
              onSubmitEdit={handleSubmit(onSubmit)}
              setIsEditingCount={setIsEditingCount}
              title="Communication Task"
            >
              {({ isEditing }) => (
                <Controller
                  control={control}
                  name="communication_task"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <CardInput
                        defaultValue={parsedData.communication_task}
                        error={error?.message}
                        isEditing={isEditing}
                        isFetching={isLoading || isOnePageStrategy.isQuerying}
                        onChange={onChange}
                        placeholder="Input the communication task here"
                        showTitle={false}
                        title="Communication Task"
                        value={value}
                      />
                    );
                  }}
                />
              )}
            </SectionCard>

            <SectionCard
              cols={2}
              disabledSave={disabledSave || isPending}
              isEditingCount={isEditingCount}
              isEditor={isEditor}
              isFetching={isLoading || isOnePageStrategy.isQuerying}
              onCancelEdit={handleCancelEditSection}
              onSubmitEdit={handleSubmit(onSubmit)}
              setIsEditingCount={setIsEditingCount}
              title="Audience Insights"
            >
              {({ isEditing, gridColumnClass }) => (
                <>
                  {!isLoading && !isOnePageStrategy.isQuerying
                    ? parsedData.selected_audience_archetypes.map(
                        (item, index) => (
                          <Fragment key={index}>
                            <h3 className="pb-4 text-15 font-bold text-blue-redx">
                              {`Archetype ${index + 1} - ${
                                item.archetype_name || ''
                              }`}
                            </h3>
                            <div
                              className={`grid ${gridColumnClass} gap-x-24 gap-y-15 pb-8`}
                            >
                              <Controller
                                control={control}
                                name={`selected_audience_archetypes.${index}.onepage_content.audience_insights.pain_point`}
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => {
                                  return (
                                    <CardInput
                                      defaultValue={
                                        item.onepage_content.audience_insights
                                          .pain_point
                                      }
                                      error={error?.message}
                                      isEditing={isEditing}
                                      onChange={onChange}
                                      placeholder="Input pain point here"
                                      showTitle
                                      title="Pain Point"
                                      value={value?.toString()}
                                    />
                                  );
                                }}
                              />

                              <Controller
                                control={control}
                                name={`selected_audience_archetypes.${index}.onepage_content.audience_insights.lifestyle`}
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => {
                                  return (
                                    <CardInput
                                      defaultValue={
                                        item.onepage_content.audience_insights
                                          .lifestyle
                                      }
                                      error={error?.message}
                                      isEditing={isEditing}
                                      onChange={onChange}
                                      placeholder="Input lifestyle here"
                                      showTitle
                                      title="Lifestyle"
                                      value={value?.toString()}
                                    />
                                  );
                                }}
                              />

                              <Controller
                                control={control}
                                name={`selected_audience_archetypes.${index}.onepage_content.audience_insights.behavioral`}
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => {
                                  return (
                                    <CardInput
                                      defaultValue={
                                        item.onepage_content.audience_insights
                                          .behavioral
                                      }
                                      error={error?.message}
                                      isEditing={isEditing}
                                      onChange={onChange}
                                      placeholder="Input behavioral here"
                                      showTitle
                                      title="Behavioral"
                                      value={value?.toString()}
                                    />
                                  );
                                }}
                              />

                              <Controller
                                control={control}
                                name={`selected_audience_archetypes.${index}.onepage_content.audience_insights.current_trends`}
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => {
                                  return (
                                    <CardInput
                                      defaultValue={
                                        item.onepage_content.audience_insights
                                          .current_trends
                                      }
                                      error={error?.message}
                                      isEditing={isEditing}
                                      onChange={onChange}
                                      placeholder="Input current trends here"
                                      showTitle
                                      title="Current Trends"
                                      value={value?.toString()}
                                    />
                                  );
                                }}
                              />

                              <Controller
                                control={control}
                                name={`selected_audience_archetypes.${index}.onepage_content.audience_insights.audience_insights`}
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => {
                                  return (
                                    <CardInput
                                      defaultValue={
                                        item.onepage_content.audience_insights
                                          .audience_insights
                                      }
                                      error={error?.message}
                                      isEditing={isEditing}
                                      onChange={onChange}
                                      placeholder="Input audience insights here"
                                      showTitle
                                      title="Audience Insights"
                                      value={value?.toString()}
                                    />
                                  );
                                }}
                              />

                              <Controller
                                control={control}
                                name={`selected_audience_archetypes.${index}.onepage_content.audience_insights.cultural_insights`}
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => {
                                  return (
                                    <CardInput
                                      defaultValue={
                                        item.onepage_content.audience_insights
                                          .cultural_insights
                                      }
                                      error={error?.message}
                                      isEditing={isEditing}
                                      onChange={onChange}
                                      placeholder="Input cultural insights here"
                                      showTitle
                                      title="Cultural Insights"
                                      value={value?.toString() ?? ''}
                                      viewPlaceholder="Click edit to add cultural insights"
                                    />
                                  );
                                }}
                              />
                            </div>
                          </Fragment>
                        ),
                      )
                    : Array.from({ length: 1 }).map((_, index) => (
                        <Fragment key={index}>
                          <div className="mb-14 h-20 w-65 animate-pulse rounded-full  bg-soft-purple-redx" />
                          <div
                            className={`grid ${gridColumnClass} gap-x-24 gap-y-15 pb-8`}
                          >
                            <CardInput
                              defaultValue=""
                              isEditing={isEditing}
                              isFetching
                              onChange={() => {}}
                              placeholder="Input pain point here"
                              showTitle
                              title="Pain Point"
                              value=""
                            />

                            <CardInput
                              defaultValue=""
                              isEditing={isEditing}
                              isFetching
                              onChange={() => {}}
                              placeholder="Input life style here"
                              showTitle
                              title="Life Stye"
                              value=""
                            />

                            <CardInput
                              defaultValue=""
                              isEditing={isEditing}
                              isFetching
                              onChange={() => {}}
                              placeholder="Input behavioral here"
                              showTitle
                              title="Behavioral"
                              value=""
                            />

                            <CardInput
                              defaultValue=""
                              isEditing={isEditing}
                              isFetching
                              onChange={() => {}}
                              placeholder="Input current Trends here"
                              showTitle
                              title="Current Trends"
                              value=""
                            />

                            <CardInput
                              defaultValue=""
                              isEditing={isEditing}
                              isFetching
                              onChange={() => {}}
                              placeholder="Input audience insights here"
                              showTitle
                              title="Audience Insights"
                              value=""
                            />

                            <CardInput
                              defaultValue=""
                              isEditing={isEditing}
                              isFetching
                              onChange={() => {}}
                              placeholder="Input cultural insights here"
                              showTitle
                              title="Cultural Insights"
                              value=""
                            />
                          </div>
                        </Fragment>
                      ))}
                </>
              )}
            </SectionCard>

            <SectionCard
              cols={2}
              disabledSave={disabledSave || isPending}
              isEditingCount={isEditingCount}
              isEditor={isEditor}
              isFetching={isLoading || isOnePageStrategy.isQuerying}
              onCancelEdit={handleCancelEditSection}
              onSubmitEdit={handleSubmit(onSubmit)}
              setIsEditingCount={setIsEditingCount}
              title="Key Highlight"
            >
              {({ isEditing, gridColumnClass }) => (
                <>
                  {!isLoading && !isOnePageStrategy.isQuerying
                    ? parsedData.selected_audience_archetypes.map(
                        (item, index) => {
                          return (
                            <Fragment key={index}>
                              <h3 className="pb-4 text-15 font-bold text-blue-redx">
                                {`Archetype ${index + 1} - ${
                                  item.archetype_name || ''
                                }`}
                              </h3>
                              <div
                                className={`grid ${gridColumnClass} gap-x-24 gap-y-15 pb-8`}
                              >
                                <Controller
                                  control={control}
                                  name={`selected_audience_archetypes.${index}.onepage_content.key_highlight.emotional`}
                                  render={({
                                    field: { onChange, value },
                                    fieldState: { error },
                                  }) => {
                                    return (
                                      <CardInput
                                        defaultValue={
                                          item.onepage_content.key_highlight
                                            ?.emotional
                                        }
                                        error={error?.message}
                                        isEditing={isEditing}
                                        isFetching={
                                          isLoading ||
                                          isOnePageStrategy.isQuerying
                                        }
                                        onChange={onChange}
                                        placeholder="Input emotional here"
                                        showTitle
                                        title="Emotional"
                                        value={value?.toString()}
                                      />
                                    );
                                  }}
                                />
                                <Controller
                                  control={control}
                                  name={`selected_audience_archetypes.${index}.onepage_content.key_highlight.functional`}
                                  render={({
                                    field: { onChange, value },
                                    fieldState: { error },
                                  }) => {
                                    return (
                                      <CardInput
                                        defaultValue={
                                          item.onepage_content.key_highlight
                                            ?.functional
                                        }
                                        error={error?.message}
                                        isEditing={isEditing}
                                        isFetching={
                                          isLoading ||
                                          isOnePageStrategy.isQuerying
                                        }
                                        onChange={onChange}
                                        placeholder="Input functional here"
                                        showTitle
                                        title="Functional"
                                        value={value?.toString()}
                                      />
                                    );
                                  }}
                                />
                              </div>
                            </Fragment>
                          );
                        },
                      )
                    : Array.from({ length: 1 }).map((_, index) => (
                        <Fragment key={index}>
                          <div className="mb-14 h-20 w-65 animate-pulse rounded-full  bg-soft-purple-redx" />
                          <div
                            className={`grid ${gridColumnClass} gap-x-24 gap-y-15 pb-8`}
                          >
                            <CardInput
                              defaultValue=""
                              isEditing={isEditing}
                              isFetching
                              onChange={() => {}}
                              placeholder="Input Emotional here"
                              showTitle
                              title="Emotional"
                              value=""
                            />

                            <CardInput
                              defaultValue=""
                              isEditing={isEditing}
                              isFetching
                              onChange={() => {}}
                              placeholder="Input Functional here"
                              showTitle
                              title="Functional"
                              value=""
                            />
                          </div>
                        </Fragment>
                      ))}
                </>
              )}
            </SectionCard>

            <SectionCard
              cols={1}
              disabledSave={disabledSave || isPending}
              isEditingCount={isEditingCount}
              isEditor={isEditor}
              isFetching={isLoading || isOnePageStrategy.isQuerying}
              onCancelEdit={handleCancelEditSection}
              onSubmitEdit={handleSubmit(onSubmit)}
              setIsEditingCount={setIsEditingCount}
              title="Product Feature Benefit"
            >
              {({ isEditing }) => (
                <>
                  {!isLoading && !isOnePageStrategy.isQuerying
                    ? parsedData.selected_audience_archetypes.map(
                        (item, index) => (
                          <Fragment key={index}>
                            <h3 className="pb-4 text-15 font-bold text-blue-redx">
                              {`Archetype ${index + 1} - ${
                                item.archetype_name || ''
                              }`}
                            </h3>

                            <Controller
                              control={control}
                              name={`selected_audience_archetypes.${index}.onepage_content.product_feature_benefit`}
                              render={({
                                field: { onChange, value },
                                fieldState: { error },
                              }) => {
                                return (
                                  <CardInput
                                    defaultValue={
                                      item.onepage_content
                                        .product_feature_benefit
                                    }
                                    error={error?.message}
                                    isEditing={isEditing}
                                    isFetching={
                                      isLoading || isOnePageStrategy.isQuerying
                                    }
                                    onChange={onChange}
                                    placeholder="Input product feature benefit here"
                                    showTitle
                                    title="Product Feature Benefit"
                                    value={value?.toString()}
                                  />
                                );
                              }}
                            />
                          </Fragment>
                        ),
                      )
                    : Array.from({ length: 1 }).map((_, index) => (
                        <Fragment key={index}>
                          <div className="mb-14 h-20 w-65 animate-pulse rounded-full  bg-soft-purple-redx" />
                          <CardInput
                            defaultValue=""
                            isEditing={isEditing}
                            isFetching={isLoading}
                            onChange={() => {}}
                            placeholder="Input Product Feature Benefit here"
                            showTitle
                            title="Product Feature Benefit"
                            value=""
                          />
                        </Fragment>
                      ))}
                </>
              )}
            </SectionCard>

            <SectionCard
              cols={1}
              disabledSave={disabledSave || isPending}
              isEditingCount={isEditingCount}
              isEditor={isEditor}
              isFetching={isLoading || isOnePageStrategy.isQuerying}
              onCancelEdit={handleCancelEditSection}
              onSubmitEdit={handleSubmit(onSubmit)}
              setIsEditingCount={setIsEditingCount}
              title="Strategic Statement"
            >
              {({ isEditing, gridColumnClass }) => (
                <>
                  {!isLoading && !isOnePageStrategy.isQuerying
                    ? parsedData.selected_audience_archetypes.map(
                        (item, index) => (
                          <Fragment key={index}>
                            <h3 className="pb-4 text-15 font-bold text-blue-redx">
                              {`Archetype ${index + 1} - ${
                                item.archetype_name || ''
                              }`}
                            </h3>
                            <div
                              className={`grid ${gridColumnClass} gap-x-24 gap-y-15 pb-8`}
                            >
                              <Controller
                                control={control}
                                name={`selected_audience_archetypes.${index}.onepage_content.strategic_statement`}
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => {
                                  return (
                                    <CardInput
                                      defaultValue={
                                        item.onepage_content.strategic_statement
                                      }
                                      error={error?.message}
                                      isEditing={isEditing}
                                      onChange={onChange}
                                      placeholder="Input strategic statement here"
                                      showTitle
                                      title="Strategic Statement"
                                      value={value?.toString()}
                                    />
                                  );
                                }}
                              />
                            </div>
                          </Fragment>
                        ),
                      )
                    : Array.from({ length: 1 }).map((_, index) => (
                        <Fragment key={index}>
                          <div className="mb-14 h-20 w-65 animate-pulse rounded-full  bg-soft-purple-redx" />
                          <div
                            className={`grid ${gridColumnClass} gap-x-24 gap-y-15 pb-8`}
                          >
                            <CardInput
                              defaultValue=""
                              isEditing={isEditing}
                              isFetching
                              onChange={() => {}}
                              placeholder="Input Strategic Statement here"
                              showTitle
                              title="Strategic Statement"
                              value=""
                            />
                          </div>
                        </Fragment>
                      ))}
                </>
              )}
            </SectionCard>

            <SectionCard
              cols={2}
              disabledSave={disabledSave || isPending}
              isEditingCount={isEditingCount}
              isEditor={isEditor}
              isFetching={isLoading || isOnePageStrategy.isQuerying}
              onCancelEdit={handleCancelEditSection}
              onSubmitEdit={handleSubmit(onSubmit)}
              setIsEditingCount={setIsEditingCount}
              title="Desired Response"
            >
              {({ isEditing, gridColumnClass }) => (
                <>
                  {!isLoading && !isOnePageStrategy.isQuerying
                    ? parsedData.selected_audience_archetypes.map(
                        (item, index) => (
                          <Fragment key={index}>
                            <h3 className="pb-4 text-15 font-bold text-blue-redx">
                              {`Archetype ${index + 1} - ${
                                item.archetype_name || ''
                              }`}
                            </h3>
                            <div
                              className={`grid ${gridColumnClass} gap-x-24 gap-y-15 pb-8`}
                            >
                              <Controller
                                control={control}
                                name={`selected_audience_archetypes.${index}.onepage_content.desired_response.before`}
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => {
                                  return (
                                    <CardInput
                                      defaultValue={
                                        item.onepage_content.desired_response
                                          .before
                                      }
                                      error={error?.message}
                                      isEditing={isEditing}
                                      isFetching={isLoading}
                                      onChange={onChange}
                                      placeholder="Input before here"
                                      showTitle
                                      title="Before"
                                      value={value?.toString()}
                                    />
                                  );
                                }}
                              />
                              <Controller
                                control={control}
                                name={`selected_audience_archetypes.${index}.onepage_content.desired_response.after`}
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => {
                                  return (
                                    <CardInput
                                      defaultValue={
                                        item.onepage_content.desired_response
                                          .after
                                      }
                                      error={error?.message}
                                      isEditing={isEditing}
                                      isFetching={isLoading}
                                      onChange={onChange}
                                      placeholder="Input after here"
                                      showTitle
                                      title="After"
                                      value={value?.toString()}
                                    />
                                  );
                                }}
                              />
                            </div>
                          </Fragment>
                        ),
                      )
                    : Array.from({ length: 1 }).map((_, index) => (
                        <Fragment key={index}>
                          <div className="mb-14 h-20 w-65 animate-pulse rounded-full  bg-soft-purple-redx" />
                          <div
                            className={`grid ${gridColumnClass} gap-x-24 gap-y-15 pb-8`}
                          >
                            <CardInput
                              defaultValue=""
                              isEditing={isEditing}
                              isFetching={isLoading}
                              onChange={() => {}}
                              placeholder="Input Before here"
                              showTitle
                              title="Before"
                              value=""
                            />
                            <CardInput
                              defaultValue=""
                              isEditing={isEditing}
                              isFetching={isLoading}
                              onChange={() => {}}
                              placeholder="Input After here"
                              showTitle
                              title="After"
                              value=""
                            />
                          </div>
                        </Fragment>
                      ))}
                </>
              )}
            </SectionCard>

            <button className="hidden" type="submit">
              Save
            </button>
          </form>
        </div>
      )}
      <SelectingFunnel
        isOpen={modalFramework}
        onClose={() => setModalFramework(false)}
        project={project}
      />

      <RegenerateModal
        handleSubmit={handleRegenerateKeyTouchPoint}
        isOpen={showRegenerateModal}
        isRegenerating={isRegeneratingModal}
        onClose={() => setShowRegenerateModal(false)}
        section="one-page-strategy"
      />

      <ViewInfoModal
        isOpen={showViewInfoModal}
        onClose={() => setShowViewInfoModal(false)}
      />
    </>
  );
};

export default Index;
