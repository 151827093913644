import { Dialog, Transition, TransitionChild } from '@headlessui/react';
import { Icon } from '@iconify/react';
import React, { Fragment } from 'react';

interface ModalProps {
  isOpen: boolean;
  hasButtonClose?: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  hasButtonClose = true,
}) => {
  const handleStopPropagation = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  return (
    <Transition as={Fragment} show={isOpen}>
      <Dialog
        as="div"
        className="fixed inset-0 z-60 overflow-y-auto"
        onClick={handleStopPropagation}
        onClose={onClose}
      >
        <div className="min-h-screen px-4 text-center">
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/50 backdrop-blur" />
          </TransitionChild>
          <span
            aria-hidden="true"
            className="inline-block h-screen align-middle"
          >
            &#8203;
          </span>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="relative my-8 inline-block overflow-hidden rounded-2xl bg-white p-0 text-left align-middle shadow-xl transition-all md:max-w-xs">
              {hasButtonClose && (
                <button
                  className="absolute right-20 top-20 z-30 cursor-pointer text-black"
                  onClick={onClose}
                >
                  <Icon className="size-20" icon="mdi:close" />
                </button>
              )}
              {children}
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
