import { Icon } from '@iconify/react';
import React from 'react';
import { NavLink } from 'react-router-dom';

interface MenuLinkProps {
  to: string;
  icon?: string;
  isLoading?: boolean;
  disabled?: boolean;
  className?: string;
  children?: React.ReactNode;
}

const MenuLink: React.FC<MenuLinkProps> = ({
  children,
  to,
  icon,
  disabled = false,
  isLoading = false,
  className = '',
}) => {
  const renderLinkContent = (isActive: boolean) => (
    <>
      {icon && !isLoading && (
        <Icon
          className={`mr-20 size-24 ${
            isActive ? 'rounded-full bg-white p-3 text-blue-redx' : 'font-light'
          }`}
          icon={icon}
          style={{
            transform:
              icon === 'lucide:arrows-up-from-line' ? 'rotate(90deg)' : 'none',
          }}
        />
      )}
      {isLoading && (
        <Icon
          className="mr-20 size-24 animate-spin"
          icon="lucide:loader-circle"
        />
      )}
      {children}
    </>
  );

  return disabled ? (
    <div
      className={`flex w-full !cursor-not-allowed items-center py-2 pl-3 pr-4 text-16 !text-gray-400 ${className} font-light transition duration-150 ease-in-out`}
    >
      {renderLinkContent(false)}
    </div>
  ) : (
    <NavLink
      className={({ isActive }) =>
        `w-full flex items-center text-16 pl-3 pr-4 py-2 ${
          isActive && !disabled
            ? '!text-white font-bold'
            : '!text-white font-light'
        } ${
          disabled ? '!text-gray-400 !cursor-not-allowed ' : 'cursor-pointer'
        } ${className} transition duration-150 ease-in-out`
      }
      end
      to={to}
    >
      {({ isActive }) => renderLinkContent(isActive)}
    </NavLink>
  );
};

export default MenuLink;
