import { useQueryClient } from '@tanstack/react-query';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import GenerateLoading from '@/Components/GenerateLoading';
import RegenerateModal from '@/Components/Modals/RegenerateModal';
import ViewInfoModal from '@/Components/Modals/ViewInfoModal';
import RegenerateButton from '@/Components/Projects/RegenerateButton';
import SourcesList from '@/Components/Projects/SourcesList';
import ErrorLLM from '@/Components/Toast/ErrorLLM';
import {
  saveInformation,
  saveRouteNext,
  saveRoutePrev,
  saveSubInformation,
  setEnableHistoryStatus,
} from '@/Context/actions/projectActions';
import { useGenerate } from '@/Context/hooks/useGenerate';
import { ProjectContext } from '@/Context/ProjectContext';
import type { RouteOption } from '@/Context/reducer/projectReducer';
import { historiesFilter } from '@/Helpers/filter';
import useDifferentArchetypes from '@/Hooks/react-query/audience-archetype/useDifferentArchetypes';
import useHistoryLatest from '@/Hooks/react-query/useHistoryLatest';
import useHistoryStatus from '@/Hooks/react-query/useHistoryStatus';
import { useUpdateHistory } from '@/Hooks/react-query/useUpdateHistory';
import useUser from '@/Hooks/react-query/useUser';
import useRolesBadge from '@/Hooks/useRolesBadge';
import { fetcher } from '@/Services/axios';
import type { CompetitorAnalysisProps } from '@/Types/competitor_analysis';
import type { ProjectProps, SubsectionDataType } from '@/Types/projects';
import type { SectionList } from '@/Types/tabs';
import { progressPortion } from '@/Utils/dispatcher';
import { triggerGTMEvent } from '@/Utils/gtm';
import { jsonPurify, stringToArray } from '@/Utils/string';

import Card from './Partials/Card';
import NotFoundCompetitor from './Partials/NotFoundCompetitor';

const Index: React.FC<{ project: ProjectProps }> = ({ project }) => {
  const [state, dispatch] = useContext(ProjectContext);
  const [isEditingCount, setIsEditingCount] = useState(0);
  const [parsedData, setParsedData] = useState<CompetitorAnalysisProps[]>([]);
  const maxGenerateLimit = project.max_generated_data;
  const navigate = useNavigate();
  const {
    isAudienceArchetypeComplete,
    isCompetitorAnalysisComplete,
    isOpportunityAmongCompetitionComplete,
    isPending: isPendingHistoryStatus,
  } = useHistoryStatus(project);
  const { data: user } = useUser();
  const { roles } = useRolesBadge(project, user);
  const { mutate } = useUpdateHistory();
  const queryClient = useQueryClient();
  const [isGenerate, setIsGenerate] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isRegenerating, setIsReGenerating] = useState(false);
  const [showRegenerateModal, setShowRegenerateModal] = useState(false);

  const { data: differentArchetypes } = useDifferentArchetypes(project);

  const isEditor = roles.includes('Owner') || roles.includes('Strategist');
  const totalRegenerate: number = state.competitorAnalysis.data.total_regenerate
    ? state.competitorAnalysis.data.total_regenerate
    : 0;

  const checkSelectedArchetypeHasChanging = async () => {
    queryClient.resetQueries({
      queryKey: ['opportunities', project.slug],
      exact: true,
    });

    const { data } = await fetcher.get(
      `/${project.slug}/competitor-analysis/check-regenerate`,
    );

    if (
      data?.data.has_new_archetype ||
      isOpportunityAmongCompetitionComplete.isRegenerated
    ) {
      setShowRegenerateModal(
        data.data.has_new_archetype ||
          isOpportunityAmongCompetitionComplete.isRegenerated,
      );
      return;
    }

    navigate(`/${project.slug}/opportunity-among-competition`);
  };

  const routeOptionValue: RouteOption = {
    label: 'Generate Opportunity Among Competition',
    isActive: true,
    isGenerate: true,
    onClick: () => {
      if (isCompetitorAnalysisComplete.isQuerying) {
        dispatch(
          saveSubInformation(
            `{text-error-redx} Please wait competitor is still generating`,
          ),
        );
        return;
      }

      checkSelectedArchetypeHasChanging();
    },
  };

  const {
    handleGenerateOpportunityAmongCompetition,
    handleGenerateCompetitorAnalysis,
    handleEditedCompetitorAnalysis,
    handleSetCompetitorAnalysisData,
  } = useGenerate(dispatch);

  const handleSetParsedData = () => {
    setIsError(false);
    try {
      const { content } = state.competitorAnalysis.data;
      if (content) {
        setParsedData(jsonPurify(content));
      }
    } catch (error) {
      setIsError(true);
    }
  };

  const handleSaveEditedData = (
    data: CompetitorAnalysisProps,
    index: number,
  ) => {
    const mappedData = {
      ...data,
      features_benefits: stringToArray(data.features_benefits),
      key_touch_points: stringToArray(data.key_touch_points),
      opportunities: stringToArray(data.opportunities),
    };

    parsedData[index] = mappedData;
    handleEditedCompetitorAnalysis()(
      project.slug,
      state.competitorAnalysis.data.history_id,
      JSON.stringify(parsedData),
    );

    mutate({
      payload: JSON.stringify(parsedData),
      historyId: state.competitorAnalysis.data.history_id,
      projectSlug: project.slug,
    });
  };

  const handleRegenerateButton = (prompt: string) => {
    triggerGTMEvent({
      event: `Regenerate Competitor Analysis`,
      eventCategory: `Regenerate Competitor Analysis Click`,
      eventAction: 'Click',
      eventLabel: 'Competitor Analysis',
      userId: user.email,
      data: { slug: project.slug, prompt },
    });

    setIsGenerate(true);
    setParsedData([]);
    handleGenerateCompetitorAnalysis()(project.slug, prompt);
    dispatch(setEnableHistoryStatus(true));
  };

  const handleRegenerateOpportunityButton = async () => {
    triggerGTMEvent({
      event: `Generate Opportunity Among Competition`,
      eventCategory: `Generate Challenge And Task Click`,
      eventAction: 'Click',
      eventLabel: 'Opportunity Among Competition',
      userId: user.email,
      data: project.slug,
    });
    setIsReGenerating(true);
    handleGenerateOpportunityAmongCompetition()(project.slug, null);
    navigate(`/${project.slug}/opportunity-among-competition`);
    dispatch(setEnableHistoryStatus(true));
  };

  const [showViewInfoModal, setShowViewInfoModal] = useState(false);
  const { data, isLoading, refetch } = useHistoryLatest(project);

  useEffect(() => {
    const competitorAnalysis = historiesFilter({
      data,
      section: 'competitor_analysis',
      subSection: null,
    });
    if (competitorAnalysis?.history_id) {
      handleSetCompetitorAnalysisData()(
        competitorAnalysis as SubsectionDataType,
      );
    } else if (
      !isLoading &&
      !isPendingHistoryStatus &&
      isCompetitorAnalysisComplete.status === false
    ) {
      setIsGenerate(true);
      if (!isCompetitorAnalysisComplete.isQuerying)
        handleGenerateCompetitorAnalysis()(project.slug);
    }
  }, [data, isCompetitorAnalysisComplete]);

  useEffect(() => {
    dispatch(saveRouteNext(routeOptionValue));
    dispatch(
      saveRoutePrev({
        label: 'Back',
        isActive: true,
        onClick: () => {
          navigate(`/${project.slug}/challenge-and-communication-task`);
        },
      }),
    );
    dispatch(saveInformation(''));
    dispatch(saveSubInformation(''));
  }, []);

  useEffect(() => {
    isCompetitorAnalysisComplete.status &&
      dispatch(
        saveRouteNext({
          ...routeOptionValue,
          isInactive: !!isGenerate,
          isDisabled: isError,
        }),
      );
  }, [isError, isCompetitorAnalysisComplete, isGenerate]);

  useEffect(() => {
    if (
      !isCompetitorAnalysisComplete.isQuerying &&
      isCompetitorAnalysisComplete.status
    ) {
      refetch();
    }

    if (!isPendingHistoryStatus && isCompetitorAnalysisComplete.isQuerying) {
      setIsGenerate(true);
    }

    if (
      isCompetitorAnalysisComplete.status &&
      !isCompetitorAnalysisComplete.isQuerying &&
      parsedData.length
    ) {
      setIsGenerate(false);
    }

    if (isCompetitorAnalysisComplete.isError) {
      toast.custom((t) => <ErrorLLM t={t} />);
    }
  }, [isCompetitorAnalysisComplete, parsedData, isPendingHistoryStatus]);

  useEffect(() => {
    handleSetParsedData();
  }, [state.competitorAnalysis.data]);

  useEffect(() => {
    if (isEditor) {
      const archetypeCount =
        differentArchetypes?.counted_archetype?.archetype_count ?? 0;

      routeOptionValue.isInactive =
        isGenerate ||
        isCompetitorAnalysisComplete.isQuerying ||
        !isCompetitorAnalysisComplete.status ||
        +archetypeCount === 0 ||
        !isAudienceArchetypeComplete.status;

      if (isOpportunityAmongCompetitionComplete.isRegenerated) {
        routeOptionValue.label = 'Regenerate Opportunity Among Competition';
        routeOptionValue.isGenerate = true;
      }

      if (
        isOpportunityAmongCompetitionComplete.status &&
        !isOpportunityAmongCompetitionComplete.isRegenerated
      ) {
        routeOptionValue.label = 'Next to Opportunity Among Competition';
        routeOptionValue.isGenerate = false;
      }

      if (
        !isOpportunityAmongCompetitionComplete.status &&
        !isOpportunityAmongCompetitionComplete.isRegenerated
      ) {
        routeOptionValue.label = ' Generate Opportunity Among Competition';
        routeOptionValue.isGenerate = true;
      }

      routeOptionValue.onClick = () => {
        if (isCompetitorAnalysisComplete.isQuerying) {
          dispatch(
            saveSubInformation(
              `{text-error-redx} Please wait competitor is still generating`,
            ),
          );
          return;
        }

        if (isOpportunityAmongCompetitionComplete.isQuerying) {
          dispatch(
            saveSubInformation(
              `{text-error-redx} Please wait, idea is still generating`,
            ),
          );

          setTimeout(() => {
            dispatch(saveSubInformation(''));
          }, 3000);
          return;
        }

        if (!isAudienceArchetypeComplete.status || +archetypeCount === 0) {
          dispatch(
            saveSubInformation(
              `{text-error-redx} Please generate & choose Audience Archetype first.`,
            ),
          );

          setTimeout(() => {
            dispatch(saveSubInformation(''));
          }, 3000);
          return;
        }

        if (isEditingCount > 0) {
          dispatch(
            saveSubInformation(`{text-error-redx} Please do save first`),
          );

          setTimeout(() => {
            dispatch(saveSubInformation(''));
          }, 3000);
          return;
        }

        checkSelectedArchetypeHasChanging();
      };
    } else {
      routeOptionValue.label = 'Next to Opportunity Among Competition';
      routeOptionValue.isGenerate = false;
      routeOptionValue.isInactive =
        !isOpportunityAmongCompetitionComplete.status ||
        isOpportunityAmongCompetitionComplete.isQuerying ||
        differentArchetypes?.is_different_archetype.opportunities;

      routeOptionValue.onClick = () => {
        if (isCompetitorAnalysisComplete.isQuerying) {
          dispatch(
            saveSubInformation(
              `{text-error-redx} Please wait competitor is still generating`,
            ),
          );
          return;
        }

        if (
          !isOpportunityAmongCompetitionComplete.status ||
          differentArchetypes?.is_different_archetype.opportunities
        ) {
          setShowViewInfoModal(true);
          return;
        }

        navigate(`/${project.slug}/opportunity-among-competition`);
      };
    }

    if (isEditingCount > 0) {
      routeOptionValue.isInactive = true;
    }

    dispatch(saveRouteNext({ ...routeOptionValue }));
  }, [
    isAudienceArchetypeComplete,
    isOpportunityAmongCompetitionComplete,
    isGenerate,
    isEditor,
    isCompetitorAnalysisComplete,
    isEditingCount,
    differentArchetypes,
  ]);

  const sectionList: SectionList = {
    title: 'Competitor Analysis',
    value: '',
    section: 'competitor_analysis',
  };

  return (
    <>
      <Helmet>
        <title>Competitor Analysis</title>
      </Helmet>
      {isGenerate ? (
        <GenerateLoading
          progress={progressPortion({
            progress: Number(
              (state.competitorAnalysis.progress / 2).toFixed(0),
            ),
            isQuerying: isCompetitorAnalysisComplete.isQuerying,
            isContentAvailable: parsedData?.length > 0,
          })}
          project={project}
          section={sectionList}
          showEmailNotification={isCompetitorAnalysisComplete.isSendingEmail}
        />
      ) : (
        <div className="size-full">
          <div className="sticky top-56 z-20 flex items-center justify-between bg-white py-16">
            <div className="flex w-full flex-col">
              <h1 className="mb-8 text-25 font-bold leading-none">
                Competitor Analysis
              </h1>
              <span className="text-15 font-normal text-grey-redx">
                Please review and edit this information, considering that the
                provided reference may not always be accurate.
              </span>
            </div>
            {isEditor && !isError && (
              <RegenerateButton
                limit={totalRegenerate}
                maxLimit={maxGenerateLimit}
                onSubmit={(form) => handleRegenerateButton(form.prompt)}
                section="competitor-analysis"
              />
            )}
          </div>

          {!isLoading && isError ? (
            <NotFoundCompetitor project={project} />
          ) : (
            <>
              {!isLoading && !isCompetitorAnalysisComplete.isQuerying ? (
                <>
                  {parsedData.map((item, index) => (
                    <Card
                      key={index}
                      index={index}
                      isCanEdit={isEditor}
                      isEditingCount={isEditingCount}
                      item={item}
                      onSaveEditedData={handleSaveEditedData}
                      setIsEditingCount={setIsEditingCount}
                      user={user}
                    />
                  ))}
                </>
              ) : (
                <Card
                  index={-1}
                  isCanEdit={isEditor}
                  isEditingCount={isEditingCount}
                  isFetching
                  item={{
                    brand_name: '',
                    product_name: '',
                    communication_angle: '',
                    communication_idea: '',
                    communication_tone: '',
                    features_benefits: '',
                    key_touch_points: '',
                    opportunities: '',
                  }}
                  onSaveEditedData={handleSaveEditedData}
                  user={user}
                />
              )}

              <div className="mr-1 flex w-full pb-60">
                {!isLoading && state.competitorAnalysis.data.sources && (
                  <SourcesList
                    sources={state.competitorAnalysis.data.sources}
                  />
                )}
                {isLoading && (
                  <div className="inline-flex gap-10">
                    {Array.from({ length: 3 }).map((_, index) => (
                      <div
                        key={index}
                        className="full flex h-65 min-w-363 animate-pulse items-center gap-x-12 rounded-10 border-1 border-soft-purple-redx bg-soft-purple-redx px-15 py-12"
                      />
                    ))}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      )}

      <RegenerateModal
        handleSubmit={handleRegenerateOpportunityButton}
        isOpen={showRegenerateModal}
        isRegenerating={isRegenerating}
        onClose={() => setShowRegenerateModal(false)}
        section="competitor-analysis"
      />

      <ViewInfoModal
        isOpen={showViewInfoModal}
        onClose={() => setShowViewInfoModal(false)}
      />
    </>
  );
};

export default Index;
