import { Icon } from '@iconify/react/dist/iconify.js';
import React from 'react';

import ModalCenter from '@/Components/Modals/Center';

type SectionLabel =
  | 'Product Research'
  | 'Market Research: Industry'
  | 'Target Audience'
  | 'Audience Archetype'
  | 'Key Insight'
  | 'Challenge & Communication Task'
  | 'Competitor Analysis'
  | 'Opportunity Among Competition'
  | 'One-Page Strategy'
  | 'Key Touch Point'
  | 'Creative Idea'
  | 'Idea Alignment'
  | 'Idea Personalization'
  | 'Idea Expansion';

const ChangingImpactModal: React.FC<{
  section: string;
  isRegenerating: boolean;
  isOpen: boolean;
  onClose: () => void;
  handleSubmit: () => void;
  visibleSections: SectionLabel[];
}> = ({
  isOpen,
  isRegenerating,
  section,
  onClose,
  handleSubmit,
  visibleSections,
}) => {
  const sections = [
    {
      icon: 'lucide:package-search',
      label: 'Product Research',
    },
    {
      icon: 'lucide:file-line-chart',
      label: 'Market Research: Industry',
    },
    {
      icon: 'lucide:users-round',
      label: 'Target Audience',
    },
    {
      icon: 'lucide:book-user',
      label: 'Audience Archetype',
    },
    {
      icon: 'lucide:lightbulb',
      label: 'Key Insight',
    },
    {
      icon: 'lucide:puzzle',
      label: 'Challenge & Communication Task',
    },
    {
      icon: 'lucide:sword',
      label: 'Competitor Analysis',
    },
    {
      icon: 'lucide:split',
      label: 'Opportunity Among Competition',
    },
    {
      icon: 'lucide:goal',
      label: 'One-Page Strategy',
    },
    {
      icon: 'lucide:key-square',
      label: 'Key Touch Point',
    },
    {
      icon: 'lucide:sprout',
      label: 'Creative Idea',
    },
    {
      icon: 'lucide:arrows-up-from-line',
      label: 'Idea Alignment',
    },
    {
      icon: 'lucide:person-standing',
      label: 'Idea Personalization',
    },
    {
      icon: 'lucide:expand',
      label: 'Idea Expansion',
    },
  ];

  const filteredSections = sections.filter((sec) =>
    visibleSections.includes(sec.label as SectionLabel),
  );

  const btnDisabledClass = isRegenerating
    ? 'bg-placeholder-redx border-placeholder-redx cursor-not-allowed'
    : `bg-blue-redx border-blue-redx cursor-pointer`;

  return (
    <ModalCenter hasButtonClose={false} isOpen={isOpen} onClose={onClose}>
      <div className="relative w-full">
        <div className="flex transition-transform duration-500">
          <div className="flex w-full shrink-0 flex-col gap-35 p-35">
            <div className="flex flex-col gap-16">
              <h1 className="text-32 font-semibold leading-56 text-black-redx md:text-18 md:leading-relaxed">
                Changing may impact other areas
              </h1>
              <p className="text-16 font-normal leading-24 text-black-redx  md:leading-relaxed">
                Updating this page will affect other sections, as listed below.
                The system will update the related sections, potentially
                adjusting your previous results in part or in full.
              </p>
            </div>
            <div className="flex  flex-col gap-16">
              <h3 className="border-b-1 border-b-stroke-redx pb-4 text-16 font-bold leading-24 text-black-redx md:leading-relaxed">
                Impacted Sections
              </h3>
              <ul className="flex flex-col gap-8 ">
                {filteredSections.map((section) => (
                  <div className="inline-flex items-center gap-4">
                    <Icon className=" size-16 font-light" icon={section.icon} />

                    <span className="text-16 font-normal leading-20">
                      {section.label}
                    </span>
                  </div>
                ))}
              </ul>
            </div>
            <div className="inline-flex w-full justify-end gap-16 md:flex md:flex-col-reverse">
              <button
                className="min-w-200 rounded-8 border-1 border-blue-redx bg-transparent p-16 font-semibold text-blue-redx"
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                className={`min-w-200 p-16 ${btnDisabledClass} rounded-8 font-semibold text-white`}
                disabled={isRegenerating}
                id={`btn-regenerate-${section}`}
                onClick={handleSubmit}
              >
                {isRegenerating ? 'Loading...' : 'Proceed Anyway'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </ModalCenter>
  );
};

export default ChangingImpactModal;
