/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-console */
import { zodResolver } from '@hookform/resolvers/zod';
import { Icon } from '@iconify/react/dist/iconify.js';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import GenerateLoading from '@/Components/GenerateLoading';
import MarkdownEditor from '@/Components/MarkdownEditor';
import EditButton from '@/Components/Projects/EditButton';
import RegenerateButton from '@/Components/Projects/RegenerateButton';
import SourcesList from '@/Components/Projects/SourcesList';
import { useGenerate } from '@/Context/hooks/useGenerate';
import { ProjectContext } from '@/Context/ProjectContext';
import useUpdateTargetAudience from '@/Hooks/react-query/target-audience/useUpdateTargetAudience';
import useHistoryStatus from '@/Hooks/react-query/useHistoryStatus';
import useUser from '@/Hooks/react-query/useUser';
import useRolesBadge from '@/Hooks/useRolesBadge';
import type { ProjectProps, SourcesType } from '@/Types/projects';
import type { SectionList } from '@/Types/tabs';
import type {
  MediaConsumptionPatternsProps,
  TargetAudienceItem,
} from '@/Types/target_audience';
import type { AudienceMediaConsumptionSchema } from '@/Types/target_audience/schema-audience-media-consumption-pattern';
import { AudienceMediaConsumption } from '@/Types/target_audience/schema-audience-media-consumption-pattern';
import { progressPortion } from '@/Utils/dispatcher';
import { triggerGTMEvent } from '@/Utils/gtm';

import { jsonToMarkdown, markdownToJson } from '../Utils/utils';
import TargetAudienceList from './TargetAudienceList';

interface Props {
  project: ProjectProps;
  section: SectionList;
  isEditing: boolean;
  isLoading: boolean;
  isGenerateMediaConsumptionPatterns: boolean;
  toggleIsEditing: () => void;
}

const CardMediaConsumptionPatterns: React.FC<Props> = ({
  project,
  section,
  isEditing,
  isLoading,
  isGenerateMediaConsumptionPatterns,
  toggleIsEditing,
}) => {
  const [isGenerate, setIsGenerate] = useState(false);
  const [state, dispatch] = useContext(ProjectContext);
  const maxGenerateLimit = project.max_generated_data;
  const { data: user } = useUser();
  const { roles } = useRolesBadge(project, user);
  const { mutate: updateTargetAudience } = useUpdateTargetAudience();
  const [parsedData, setParsedData] =
    useState<MediaConsumptionPatternsProps | null>({
      media_consumption_pattern: [],
    });
  const [mediaConsumptionPatterns, setMediaConsumptionPatterns] = useState<
    TargetAudienceItem[]
  >([]);

  const {
    handleGenerateTargetAudienceMediaConsumptionPatterns,
    handleSetTargetAudienceMediaConsumptionPatterns,
  } = useGenerate(dispatch);

  const stateProps = state.targetAudience.media_consumption_patterns;
  const { progress } = stateProps;
  const { data } = stateProps;
  const { content } = data;
  const { sources } = data;
  const totalRegenerate: number = data.total_regenerate
    ? data.total_regenerate
    : 0;
  const isEditor = roles.includes('Owner') || roles.includes('Strategist');

  const { reset, setValue, getValues } =
    useForm<AudienceMediaConsumptionSchema>({
      resolver: zodResolver(AudienceMediaConsumption),
      defaultValues: {
        media_consumption_patterns: '',
      },
      mode: 'all',
    });

  const { isTargetAudienceMediaConsumptionPattern: isTargetAudience } =
    useHistoryStatus(project);

  const handleSetParsedData = () => {
    const stringContent = content;
    if (stringContent) {
      try {
        const parsedContent = JSON.parse(stringContent);

        reset({
          media_consumption_patterns: jsonToMarkdown(
            parsedContent.media_consumption_pattern,
          ),
        });

        setParsedData(parsedContent);
        setMediaConsumptionPatterns(parsedContent.media_consumption_pattern);
      } catch (error) {
        console.error(error, 'error media consumption patterns');
        console.log(
          'error while parsing =>',
          stringContent,
          'media consumption patterns',
        );
      }
    }
  };

  const handleSaveData = () => {
    toggleIsEditing();

    const formattedContent: MediaConsumptionPatternsProps = {
      media_consumption_pattern: markdownToJson(
        getValues('media_consumption_patterns'),
      ),
    };

    triggerGTMEvent({
      event: `Edit Media Consumption Patterns`,
      eventCategory: `Edit Target Audience Media Consumption Patterns Click`,
      eventAction: 'Click',
      eventLabel: 'Media Consumption Patterns',
      userId: user.email,
      data: JSON.stringify(formattedContent),
    });

    updateTargetAudience(
      {
        payload: JSON.stringify(formattedContent),
        historyId: data.history_id,
        projectSlug: project.slug,
      },
      {
        onSuccess: (data) => {
          handleSetTargetAudienceMediaConsumptionPatterns()(data.data);
        },
      },
    );
  };

  const handleRegenerateButton = (prompt: string) => {
    setIsGenerate(true);
    triggerGTMEvent({
      event: `Regenerate Media Consumption Patterns`,
      eventCategory: `Regenerate Target Audience Media Consumption Patterns Click`,
      eventAction: 'Click',
      eventLabel: 'Media Consumption Patterns',
      userId: user.email,
      data: prompt,
    });

    handleGenerateTargetAudienceMediaConsumptionPatterns()(
      project.slug,
      prompt,
    );
  };

  useEffect(() => {
    handleSetParsedData();
  }, [data]);

  useEffect(() => {
    if (
      !isTargetAudience.isQuerying &&
      parsedData?.media_consumption_pattern.length
    ) {
      setIsGenerate(false);
    }
  }, [isTargetAudience.isQuerying, parsedData]);

  useEffect(() => {
    if (isGenerateMediaConsumptionPatterns) {
      setIsGenerate(true);
    }
  }, [isGenerateMediaConsumptionPatterns]);

  if (isGenerate) {
    return (
      <GenerateLoading
        progress={progressPortion({
          progress: Number((progress / 2).toFixed(0)),
          isQuerying: isTargetAudience.isQuerying,
          isContentAvailable:
            parsedData?.media_consumption_pattern.length !== 0,
        })}
        project={project}
        section={section}
        showEmailNotification={isTargetAudience.isSendingEmail}
      />
    );
  }

  return (
    <>
      {/* content */}
      <div className="flex flex-col gap-24 text-black-redx">
        {parsedData && (
          <div className="flex flex-col gap-5">
            <div className="flex items-center justify-between">
              <h3 className="text-16 font-bold leading-19">
                Media Consumption Patterns
              </h3>
              {isEditor && !isEditing && (
                <RegenerateButton
                  limit={totalRegenerate}
                  maxLimit={maxGenerateLimit}
                  onSubmit={(formData) =>
                    handleRegenerateButton(formData.prompt)
                  }
                  section="media-consumption-pattern"
                />
              )}
            </div>
            {isEditing ? (
              <MarkdownEditor
                content={jsonToMarkdown(mediaConsumptionPatterns)}
                onChange={(html) => {
                  const json = markdownToJson(html);
                  setValue('media_consumption_patterns', jsonToMarkdown(json));
                  setMediaConsumptionPatterns(json);
                }}
              />
            ) : (
              <>
                {isLoading ? (
                  <div className="full mt-5 h-100 w-full animate-pulse gap-x-12 rounded-10 border-1 border-soft-purple-redx bg-soft-purple-redx px-15" />
                ) : (
                  <TargetAudienceList items={mediaConsumptionPatterns} />
                )}
              </>
            )}
          </div>
        )}
      </div>

      <div className="my-24 flex w-full items-center justify-end gap-x-15">
        {isEditor &&
          (isEditing ? (
            <>
              <button
                className="inline-flex cursor-pointer items-center gap-x-4 text-error-redx"
                onClick={() => {
                  handleSetParsedData();
                  toggleIsEditing();
                }}
              >
                <span className="text-15 font-semibold">Cancel</span>
                <Icon className="size-20" icon="lucide:x" />
              </button>
              <button
                className="inline-flex cursor-pointer items-center gap-x-4 text-blue-redx"
                id="btn-edit-target-audience-media-consumption"
                onClick={handleSaveData}
              >
                <span className="text-15 font-semibold">Save</span>
                <Icon className="size-20" icon="lucide:save" />
              </button>
            </>
          ) : (
            <EditButton toggleEditing={toggleIsEditing} />
          ))}
      </div>
      <div className="mr-1 flex w-full pb-60">
        {!isLoading && sources && (
          <SourcesList sources={sources as SourcesType[]} />
        )}
        {isLoading && (
          <div className="inline-flex gap-10">
            {Array.from({ length: 3 }).map((_, index) => (
              <div
                key={index}
                className="full flex h-65 min-w-363 animate-pulse items-center gap-x-12 rounded-10 border-1 border-soft-purple-redx bg-soft-purple-redx px-15 py-12"
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default CardMediaConsumptionPatterns;
